import * as app from './App/epics';
// import * as relationship from './Relationship/epics';
// import * as entity from './Entity/epics';
import * as contextPage from './ContextPage/epics';

const AllEpics = {
    app,
    // relationship,
    // entity,
    contextPage
}

export default AllEpics;