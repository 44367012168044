import { IFeatureModel, IProfileModel } from "proxy/apiProxy";

export type LanguageCode = "en" | "fr" | "nl" | "de" | "he";

export interface ICultureItem {
    code: LanguageCode;
    label: string;
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const cultures: ICultureItem[] = [
    { code: "en", label: "English" },
    { code: "fr", label: "Français" },
    { code: "nl", label: "Nederlands" },
    { code: "de", label: "Deutsch" },
    { code: "he", label: "Hebrew" },
]


export interface IGrantRequestOnManagedPortfolio {
    feature: IFeatureModel;
    portfolioId: number;
}
export interface IGrantRequestOnManagedSicav {
    feature: IFeatureModel;
    sicavId: number;
}
export interface IGrantRequestOnManagedInvestor {
    feature: IFeatureModel;
    investorId: number;
}

export type GrantRequest = IFeatureModel |
    IGrantRequestOnManagedPortfolio |
    IGrantRequestOnManagedSicav |
    IGrantRequestOnManagedInvestor |
    boolean;
function isPortfolioGrantRequest(grantRequest: any): grantRequest is IGrantRequestOnManagedPortfolio {
    if (typeof grantRequest !== "object") {
        return false;
    }
    return typeof (grantRequest as IGrantRequestOnManagedPortfolio).portfolioId === "number";
}
function isSicavGrantRequest(grantRequest: any): grantRequest is IGrantRequestOnManagedSicav {
    if (typeof grantRequest !== "object") {
        return false;
    }
    return typeof (grantRequest as IGrantRequestOnManagedSicav).sicavId === "number";
}
function isInvestorGrantRequest(grantRequest: any): grantRequest is IGrantRequestOnManagedInvestor {
    if (typeof grantRequest !== "object") {
        return false;
    }
    return typeof (grantRequest as IGrantRequestOnManagedInvestor).investorId === "number";
}
function isGrantRequest(grantRequest: any): grantRequest is GrantRequest {
    if (typeof grantRequest === "object") {
        return typeof (grantRequest as IGrantRequestOnManagedPortfolio).portfolioId === "number"
            || typeof (grantRequest as IGrantRequestOnManagedSicav).sicavId === "number"
            || typeof (grantRequest as IGrantRequestOnManagedInvestor).investorId === "number";
    }
    else {
        return typeof grantRequest === "string"
            || typeof grantRequest === "boolean";
    }
}

/**
 * Defines if the profile is granted based on some requests
 * @param profile The profile this is the subject of the analysis
 * @param grantRequests the requests to evaluate. If request itself: it is directly evaluated, if array of requests: any of them has to match, if record of requests: all of them has to match
 */
export function isGranted(
    profile: IProfileModel | undefined,
    // userFeatures: IFeatureModel[], 
    grantRequests: GrantRequest | GrantRequest[] | Record<string, GrantRequest>) {
    if (!profile) {
        return false;
    }
    if (typeof grantRequests === "boolean") {
        return grantRequests;
    }
    if ((profile.featuresOnSomeScopes).includes(IFeatureModel.SysAdmin)) {
        return true;
    }
    if (isGrantRequest(grantRequests)) {
        grantRequests = [grantRequests];
    }
    if (!Array.isArray(grantRequests)) {
        // ALL OF THEM MUST MATCH
        for (const grantRequest of Object.values(grantRequests)) {
            if (typeof grantRequest === "boolean") {
                if (!grantRequest) {
                    return false;
                }
            }
            else if (typeof grantRequest === "string") {
                if (!(profile.featuresOnSomeScopes).includes(grantRequest)) {
                    return false;
                }
            }
            else if (isPortfolioGrantRequest(grantRequest)) {
                if (!isAuthorizedOnObject(profile, grantRequest.portfolioId, profile.featuresOnPortfolio, grantRequest.feature)) {
                    return false;
                }
            }
            else if (isSicavGrantRequest(grantRequest)) {
                if (!isAuthorizedOnObject(profile, grantRequest.sicavId, profile.featuresOnSicav, grantRequest.feature)) {
                    return false;
                }
            }
            else if (isInvestorGrantRequest(grantRequest)) {
                if (!isAuthorizedOnObject(profile, grantRequest.investorId, profile.featuresOnInvestor, grantRequest.feature)) {
                    return false;
                }
            }
        }
        return true;
    }
    else {
        // ANY OF THEM MUST MATCH
        for (const grantRequest of grantRequests) {
            if (typeof grantRequest === "boolean") {
                if (grantRequest) {
                    return true;
                }
            }
            else if (typeof grantRequest === "string") {
                if ((profile.featuresOnSomeScopes).includes(grantRequest)) {
                    return true;
                }
            }
            else if (isPortfolioGrantRequest(grantRequest)) {
                if (isAuthorizedOnObject(profile, grantRequest.portfolioId, profile.featuresOnPortfolio, grantRequest.feature)) {
                    return true;
                }
            }
            else if (isSicavGrantRequest(grantRequest)) {
                if (isAuthorizedOnObject(profile, grantRequest.sicavId, profile.featuresOnSicav, grantRequest.feature)) {
                    return true;
                }
            }
            else if (isInvestorGrantRequest(grantRequest)) {
                if (isAuthorizedOnObject(profile, grantRequest.investorId, profile.featuresOnInvestor, grantRequest.feature)) {
                    return true;
                }
            }
        }
        return false;
    }
}
export type IErrors<T> = {
    [P in keyof T]?: string;
};
export interface IDictionary<T> {
    [key: number]: T;
    [key: string]: T;
};

function isAuthorizedOnObject(profile: IProfileModel, objectId: number, featuresOnObjects: Record<string | number, IFeatureModel[]>, feature: IFeatureModel) {
    if (profile.featuresOnFullScope.includes(IFeatureModel.SysAdmin)) return true;
    const featuresOnObject = featuresOnObjects[objectId] ?? [];
    if (!featuresOnObject.length) return false;
    if (featuresOnObject.includes(IFeatureModel.SysAdmin)) return true;
    return featuresOnObject.includes(feature);
}

export function isValidDate(d: Date) {
    return d instanceof Date && !isNaN(d as unknown as number);
}

export function today() {
    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
}
export function todayString() {
    return `${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}`;
}
export function addYears(date: Date, years: number) {
    return new Date(date.getFullYear() + years, date.getMonth(), date.getDate());
}
export function addMonths(date: Date, months: number) {
    return new Date(date.getFullYear(), date.getMonth() + months, date.getDate());
}
export function groupBy<T, K extends string | number, V = T>(values: T[], getKey: (value: T) => K, getValue?: (value: T) => V) {
    const getVal = getValue || ((e: T) => (e as unknown as V));
    return values.reduce((a, v) => {
        const key = getKey(v);
        const ts = a[key];
        if (!ts) {
            a[key] = [getVal(v)]
        }
        else {
            ts.push(getVal(v));
        }
        return a;
    }, {} as Record<K, V[]>);
}

export function toDictionary<T, K extends string | number, V = T>(values: T[] | undefined, getKey: (value: T) => K, getValue?: (value: T) => V): Record<K, V> {
    if (!values) {
        return {} as Record<K, V>;
    }

    const getVal = getValue || ((e: T) => (e as unknown as V));
    return values.reduce((acc, v) => { acc[getKey(v)] = getVal(v); return acc; }, {} as Record<K, V>);
}
export function distinctFilter<T>(value: T, index: number, self: T[]) {
    return self.indexOf(value) === index;
}
export function dateSort<T>(getDate: (row: T) => Date | undefined, descending: boolean = false): (rowA: T | undefined, rowB: T | undefined) => number {
    return (rowA: T | undefined, rowB: T | undefined) => {
        const dateA = rowA ? getDate(rowA) : undefined;
        const dateB = rowB ? getDate(rowB) : undefined;
        const timeA = dateA?.getTime() ?? 0;
        const timeB = dateB?.getTime() ?? 0;
        return (timeA - timeB) * (descending ? -1 : 1);
    }
}

export function toList<T>(dictionary: IDictionary<T> | undefined): T[] {
    if (!dictionary) {
        return [];
    }
    return Object.keys(dictionary).map(key => dictionary[key]);
}
export function isDate(value: any) {
    return toString.call(value) === '[object Date]';
}
export function isSameDay(date1: Date, date2: Date) {
    return date1.getDay() === date2.getDay() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}
type PropertyOf<T> = Extract<keyof T, string | number>;




// export function nameofNew<T1, K1 extends PropertyOf<Required<T1>>, K2 extends PropertyOf<Required<T1[K1]>>>(name1: K1, name2: K2): string;
// export function nameofNew<T1>(): ;
// export function nameofNew<T1>(): <
//     K1 extends PropertyOf<Required<T1>>,
//     K2 extends PropertyOf<Required<T1[K1]>>,
//     >(name1: K1, name2: K2) => string;
// // export function nameofNew<T1, K1 extends PropertyOf<Required<T1>>, K2 extends PropertyOf<Required<T1[K1]>> >(name1: K1, name2: PropertyOf<T1[K1]>): string;


// interface ITmp1 {
//     a1: string;
//     a2?: ITmp2;
//     a3?: ITmp2[];
// }

// interface ITmp2 {
//     b1: string;
//     b2: ITmp1;
//     b3: ITmp1[];
// }


// const tmp1 = oProps<ITmp1>().nameOf("a2");
// const tmp2 = oProps<ITmp1>().nameOf("a2", "b2", "a2", "b2", "a1");
// const tmp3 = oProps<ITmp1>().pathTo("a3", 10, "b3", 19, "a2", "b2");


// /**
//  * @deprecated Use oProps().nameOf()
//  */
// export const nameof = <T>(name: Extract<keyof T, string>): string => name;


interface IPropertiesAccessor<T1> {
    nameOf<K1 extends PropertyOf<Required<T1>>>(name1: K1): K1;
    nameOf<
        K1 extends PropertyOf<Required<T1>>,
        K2 extends PropertyOf<Required<T1>[K1]>
    >(name1: K1, name2: K2): K2;
    nameOf<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>
    >(name1: K1, name2: K2, name3: K3): K3;
    nameOf<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>
    >(name1: K1, name2: K2, name3: K3, name4: K4): K4;
    nameOf<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5): K5;
    nameOf<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6): K6;
    nameOf<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
        K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7): K7;
    nameOf<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
        K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>,
        K8 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>,
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7, name8: K8): K8;
    nameOf<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
        K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>,
        K8 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>,
        K9 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>[K8]>,
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7, name8: K8, name9: K9): K9;
    nameOf<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
        K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>,
        K8 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>,
        K9 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>[K8]>,
        K10 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>[K8]>[K9]>,
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7, name8: K8, name9: K9, name10: K10): K10;








    path<K1 extends PropertyOf<Required<T1>>>(name1: K1): string;
    path<
        K1 extends PropertyOf<Required<T1>>,
        K2 extends PropertyOf<Required<T1>[K1]>
    >(name1: K1, name2: K2): string;
    path<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>
    >(name1: K1, name2: K2, name3: K3): string;
    path<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>
    >(name1: K1, name2: K2, name3: K3, name4: K4): string;
    path<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5): string;
    path<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6): string;
    path<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
        K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7): string;
    path<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
        K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>,
        K8 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>,
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7, name8: K8): string;
    path<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
        K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>,
        K8 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>,
        K9 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>[K8]>,
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7, name8: K8, name9: K9): string;
    path<
        K1 extends PropertyOf<T1>,
        K2 extends PropertyOf<Required<T1>[K1]>,
        K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
        K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
        K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
        K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
        K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>,
        K8 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>,
        K9 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>[K8]>,
        K10 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>[K8]>[K9]>,
    >(name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7, name8: K8, name9: K9, name10: K10): string;















    // pathFrom<K1 extends PropertyOf<Required<T1>>>(from: string, name1: K1): K1;
    // pathFrom<
    //     K1 extends PropertyOf<Required<T1>>,
    //     K2 extends PropertyOf<Required<T1>[K1]>
    // >(from: string, name1: K1, name2: K2): string;
    // pathFrom<
    //     K1 extends PropertyOf<T1>,
    //     K2 extends PropertyOf<Required<T1>[K1]>,
    //     K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>
    // >(from: string, name1: K1, name2: K2, name3: K3): string;
    // pathFrom<
    //     K1 extends PropertyOf<T1>,
    //     K2 extends PropertyOf<Required<T1>[K1]>,
    //     K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
    //     K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>
    // >(from: string, name1: K1, name2: K2, name3: K3, name4: K4): string;
    // pathFrom<
    //     K1 extends PropertyOf<T1>,
    //     K2 extends PropertyOf<Required<T1>[K1]>,
    //     K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
    //     K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
    //     K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>
    // >(from: string, name1: K1, name2: K2, name3: K3, name4: K4, name5: K5): string;
    // pathFrom<
    //     K1 extends PropertyOf<T1>,
    //     K2 extends PropertyOf<Required<T1>[K1]>,
    //     K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
    //     K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
    //     K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
    //     K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>
    // >(from: string, name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6): string;
    // pathFrom<
    //     K1 extends PropertyOf<T1>,
    //     K2 extends PropertyOf<Required<T1>[K1]>,
    //     K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
    //     K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
    //     K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
    //     K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
    //     K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>
    // >(from: string, name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7): string;
    // pathFrom<
    //     K1 extends PropertyOf<T1>,
    //     K2 extends PropertyOf<Required<T1>[K1]>,
    //     K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
    //     K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
    //     K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
    //     K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
    //     K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>,
    //     K8 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>,
    //     >(from: string, name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7, name8: K8): string;
    // pathFrom<
    //     K1 extends PropertyOf<T1>,
    //     K2 extends PropertyOf<Required<T1>[K1]>,
    //     K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
    //     K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
    //     K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
    //     K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
    //     K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>,
    //     K8 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>,
    //     K9 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>[K8]>,
    //     >(from: string, name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7, name8: K8, name9: K9): string;
    // pathFrom<
    //     K1 extends PropertyOf<T1>,
    //     K2 extends PropertyOf<Required<T1>[K1]>,
    //     K3 extends PropertyOf<Required<Required<T1>[K1]>[K2]>,
    //     K4 extends PropertyOf<Required<Required<Required<T1>[K1]>[K2]>[K3]>,
    //     K5 extends PropertyOf<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>,
    //     K6 extends PropertyOf<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>,
    //     K7 extends PropertyOf<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>,
    //     K8 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>,
    //     K9 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>[K8]>,
    //     K10 extends PropertyOf<Required<Required<Required<Required<Required<Required<Required<Required<Required<T1>[K1]>[K2]>[K3]>[K4]>[K5]>[K6]>[K7]>[K8]>[K9]>,
    //     >(from: string, name1: K1, name2: K2, name3: K3, name4: K4, name5: K5, name6: K6, name7: K7, name8: K8, name9: K9, name10: K10): string;

}

export function oProps<T>(from?: string): IPropertiesAccessor<T> {
    return {
        nameOf: function (name: string, ...names: string[]) {
            if (names.length > 0) {
                return names[names.length - 1];
            }
            return name;
        },
        path: function (name: string, ...names: string[]) {
            if (from) {
                return [from, name, ...names].join(".");
            }
            else {
                return [name, ...names].join(".");
            }
        }
    }
}

export type Unpack<T> = T extends (infer U)[]
    ? U
    : T extends { [key: number]: (infer V) }
    ? V
    : never;
export function tryParseNumber(input: any): number | undefined {
    if (!input) {
        return;
    }
    const parsed = Number(input);
    if (isNaN(parsed)) {
        return;
    }
    return parsed;
}
export function splitPascalCase(word: string): string;
export function splitPascalCase(word: string | undefined): string | undefined;
export function splitPascalCase(word: string | undefined) {
    if (!word) {
        return undefined;
    }
    const wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
    return word.match(wordRe)?.join(" ") ?? "";
}
export function getEnumLabels(e: Record<string, string>) {
    const o = { ...e };
    Object.keys(o).forEach(k => o[k] = splitPascalCase(o[k]));
    return o;
}

export interface IBase64File {
    mimeType: string;
    content: string;
    fileName: string;
}

export interface IFileConverter<T> {
    fromFile: (fileType: File) => Promise<T>;
    toFile: (fieldType: T) => File;
}

export function fileToBase64(file: File) {
    return new Promise<IBase64File>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const splitted = (reader.result as string).split(",");
            let meta = splitted[0];
            meta = meta.substr(meta.indexOf(":") + 1);
            meta = meta.substr(0, meta.lastIndexOf(";"));
            resolve({ content: splitted[1], mimeType: meta, fileName: file.name });
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
}

// faster version
// https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
export function base64toBlob(base64: string, mimeType: string) {
    const byteString = atob(base64);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
}
export class Base64FileConverter implements IFileConverter<IBase64File> {
    public async fromFile(fileType: File) {
        return await fileToBase64(fileType);
    }
    public toFile(fieldType: IBase64File) {
        return new File([base64toBlob(fieldType.content, fieldType.mimeType)], fieldType.fileName);
    }
}

export const CurrentLocale = (window.navigator as any).userLanguage || window.navigator.language;
function getDateFormatString(): string {
    const formatObj = new Intl.DateTimeFormat(CurrentLocale).formatToParts(new Date());
    return formatObj
        .map(obj => {
            switch (obj.type) {
                case 'day':
                    return 'dd';
                case 'month':
                    return 'MM';
                case 'year':
                    return 'yyyy';
                default:
                    return obj.value;
            }
        })
        .join('');
}
export const LocaleDateFormat = getDateFormatString();
// TODO:: no `any` type!
export const mergeDeep = (target: any, source: any) => {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object && !(source[key] instanceof Date && !isNaN(source[key].valueOf()))) Object.assign(source[key], mergeDeep(target[key], source[key]))
    }
    // Join `target` and modified `source`
    Object.assign(target || {}, source)
    return target
}
export const formatPrecisePercentage = (value: number | undefined, precision: number = 2) => value?.toLocaleString(CurrentLocale, { useGrouping: true, minimumFractionDigits: precision, maximumFractionDigits: precision, style: "percent" }) ?? "";
export const formatPercentage = (value: number | undefined) => value?.toLocaleString(CurrentLocale, { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0, style: "percent" }) ?? "";
export const formatDecimal = (value: number | undefined) => value?.toLocaleString(CurrentLocale, { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? "";
export const formatPreciseDecimal = (value: number | undefined) => value?.toLocaleString(CurrentLocale, { useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4 }) ?? "";
export const formatInteger = (value: number | undefined) => value?.toLocaleString(CurrentLocale, { useGrouping: true, maximumFractionDigits: 0 }) ?? "";
export const formatCurrency = (value: number | undefined, currency: string) => value?.toLocaleString(CurrentLocale, { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, style: (currency ? "currency" : undefined), currency }) ?? "";
function formatNumberFromStart(n: number, l: number) {
    return `${'0'.repeat(l)}${n}`.substr(-l);
}
export const formatDate = (value: Date | undefined) => value ? `${formatNumberFromStart(value.getFullYear(), 4)}-${formatNumberFromStart(value.getMonth() + 1, 2)}-${formatNumberFromStart(value.getDate(), 2)}` : "";// value?.toLocaleDateString(CurrentLocale) ?? "";
// export const formatDate = (value: Date | undefined) => value?.toLocaleDateString(CurrentLocale) ?? "";
export const formatDateTime = (value: Date | undefined) => value ? `${formatDate(value)} ${value.toLocaleTimeString(CurrentLocale)}` : "";

export type ReactParameters<T> = T extends React.FunctionComponent<infer P> ? P : never;
