import { UserManager, UserManagerSettings } from 'oidc-client-ts';
import { SpaConfigGetSpaConfig_RestApi, ISpaAuthenticationConfigModel } from 'proxy/apiProxy';

let config: ISpaAuthenticationConfigModel | undefined = undefined;

let userManager: UserManager | undefined = undefined;
export function getUserManager() {
    if (userManager) return userManager;
    const config = getConfig();
    const userManagerSettings: UserManagerSettings = {
        authority: config?.authority ?? "",
        automaticSilentRenew: true,
        client_id: config?.clientId ?? "",
        loadUserInfo: false,
        redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
        response_type: 'code',
        scope: `openid profile email ${config?.audienceScope}`
    }
    return new UserManager(userManagerSettings);
}
export function getConfig() {
    if (config) return config;
    const request = new XMLHttpRequest();
    request.open(SpaConfigGetSpaConfig_RestApi.method, SpaConfigGetSpaConfig_RestApi.path, false);
    request.send();
    config = JSON.parse(request.responseText) as ISpaAuthenticationConfigModel;
    return config;
}
