import { Box, makeStyles, Tab, TabProps, Tabs, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import UserDropdown from 'components/UserDropdown';
import { useReduxSelections, useReduxActions } from 'lib/reduxStoreAccess';
import ApplicationTitle from './ApplicationTitle';
import { getUserManager } from 'lib/userManager';
import TenantsDialog from './TenantsDialog';
import { counterpartyTypes, roleDomains, rolePositions } from "components/summaries/FormInterface";
import { getEntityName } from 'lib/modelUtils';
import { useMatches } from 'react-router-dom';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import HandshakeOutlineIcon from "mdi-material-ui/HandshakeOutline";
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import { IState, PageContextDetail } from 'features/ContextPage/slice';
import BusinessIcon from '@material-ui/icons/Business';
import GroupIcon from '@material-ui/icons/Group';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { DetailType, IContextIdPayload } from 'features/mapDefaultRelatedId';
import { IUniverseScopeTypeModel } from 'proxy/apiProxy';

const useStyles = makeStyles(theme => ({
	appBar: {
		transition: theme.transitions.create(['width', 'margin'], {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.sharp,
		}),
		zIndex: theme.zIndex.drawer + 1,
	},
	toolBar: {
		"& > *:not(:first-child)": {
			marginLeft: theme.spacing(1)
		}
	},
	hide: {
		display: 'none',
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	insideToolbarFiller: {
		flexGrow: 1,
	},
	tabButton: {
		minHeight: 64,
	}
}));

function useCurrentPage(): IContextIdPayload | null {
	const { currentUser } = useReduxSelections("app");
	const matches = useMatches();
	if (!matches.length) {
		return null;
	}
	const id = matches[0].params?.id;
	if (!id) {
		if (currentUser) {
			return {
				id: currentUser.id,
				type: IUniverseScopeTypeModel.Entity
			};
		}
		return null;
	}
	return {
		id: Number(id),
		type: matches[0].params.type as DetailType
	};
}

export default function ApplicationBar() {
	const { currentUser, accessibleTenants = [], currentTenant, tenantsImageUrls } = useReduxSelections("app");
	const { switchTenant } = useReduxActions("app");
	const tenants = useMemo(() => accessibleTenants.filter(t => t.id !== currentTenant?.id).sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0), [accessibleTenants, currentTenant?.id])
	const handleLogout = useCallback(() => {
		const userManager = getUserManager();
		userManager.signoutRedirect()
	}, []);
	const classes = useStyles();
	const [tenantsOpened, setTenantsOpened] = useState(false);
	const handleOpenChangeTenant = useCallback(() => void setTenantsOpened(true), []);
	const handleCloseChangeTenant = useCallback(() => void setTenantsOpened(false), []);
	const handleTenantSelected = (tenantId: number) => {
		setTenantsOpened(false);
		switchTenant(tenantId);
	}
	const { all, dictionaries } = useReduxSelections("contextPage");
	return <>
		<AppBar position="fixed" className={classNames(classes.appBar)}>
			<Toolbar className={classes.toolBar}>
				<ApplicationTitle />
				<Box flexGrow={1} />
				<TopMenuTabs
					dictionaries={dictionaries}
					items={all} />
				{currentUser && <UserDropdown user={currentUser} onLogout={handleLogout} onChangeTenant={(accessibleTenants.length > 1) ? handleOpenChangeTenant : undefined} />}
			</Toolbar>
		</AppBar>
		<TenantsDialog accessibleTenants={tenants} opened={tenantsOpened} onCancel={handleCloseChangeTenant} onTenantSelected={handleTenantSelected} tenantsImageUrls={tenantsImageUrls} />
	</>
}

function getTabKey(pageId: IContextIdPayload | null) {
	if (!pageId) {
		return null;
	}
	return `${pageId.type}${pageId.id}`;
}
function ToContextId({ type, id }: PageContextDetail): IContextIdPayload {
	switch (type) {
		case "CompanyModel":
		case "EntityGroupModel":
		case "PersonModel":
		case "SicavModel":
			return { type: IUniverseScopeTypeModel.Entity, id };
		case "CounterpartyRelationshipModel":
			return { type: IUniverseScopeTypeModel.Counterparty, id };
		case "InvestorRelationshipModel":
			return { type: IUniverseScopeTypeModel.Investor, id };
		case "RoleRelationshipModel":
			return { type: IUniverseScopeTypeModel.Role, id };
	}
}

const typePosition: Record<PageContextDetail["type"], number> = {
	"PersonModel": 0,
	"EntityGroupModel": 1,
	"CompanyModel": 2,
	"SicavModel": 3,
	"RoleRelationshipModel": 4,
	"InvestorRelationshipModel": 5,
	"CounterpartyRelationshipModel": 6,
}

interface ITopMenuTabsProps {
	items: Record<DetailType, Record<number, PageContextDetail>>;
	dictionaries: IState["dictionaries"];
}

function TopMenuTabs({ items, dictionaries }: ITopMenuTabsProps) {
	let pages = useMemo(() => Object.values(items).flatMap(i => Object.values(i)).toSorted((a, b) => typePosition[a.type] - typePosition[b.type]), [items]);
	const currentPage = useCurrentPage();
	return <Tabs value={getTabKey(currentPage)}>
		{pages.map(page => (<PageTab
			value={getTabKey(ToContextId(page))}
			key={getTabKey(ToContextId(page))}
			detail={page}
			dictionaries={dictionaries} />))}
	</Tabs>
}

type PageTabProps = Omit<TabProps<"a">, "label" | "icon"> & {
	dictionaries: IState["dictionaries"];
	detail: PageContextDetail
}
function PageTab({ dictionaries, detail, ...props }: PageTabProps) {
	const { title, avatar: MenuIcon, subTitle } = useMemo(() => getLabel(detail, dictionaries), [dictionaries, detail])

	const typePath: DetailType = useMemo(() => {
		switch (detail.type) {
			case "CompanyModel":
			case "EntityGroupModel":
			case "PersonModel":
			case "SicavModel": return IUniverseScopeTypeModel.Entity;
			case "CounterpartyRelationshipModel": return IUniverseScopeTypeModel.Counterparty;
			case "InvestorRelationshipModel": return IUniverseScopeTypeModel.Investor;
			case "RoleRelationshipModel": return IUniverseScopeTypeModel.Role;
		}
	}, [detail.type]);

	const href = `/page/${typePath}/${detail.id}`;
	const classes = useStyles();

	return <Tab component={NavLink} to={href} className={classes.tabButton} value={getTabKey(ToContextId(detail))}
		label={
			<Box display="flex" alignItems="center" style={{ gap: 16, paddingLeft: 32, paddingRight: 32 }}>
				<MenuIcon fontSize="default" />
				<Box display="flex" flexDirection="column">
					<Typography variant="h6" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: 180 }}>{title}</Typography>
					{!!subTitle && <Typography variant="caption">{subTitle}</Typography>}
				</Box>
			</Box>
		} {...props} />
}

function getLabel(detail: PageContextDetail, dictionaries: IState["dictionaries"]) {
	switch (detail.type) {
		case "CounterpartyRelationshipModel": return {
			title: getEntityName(dictionaries.entities[detail.entityId]),
			subTitle: `As ${detail.title ?? (!!detail.counterpartyType ? counterpartyTypes[detail.counterpartyType] : "")}`,
			avatar: AccountBalanceOutlinedIcon
		};
		case "RoleRelationshipModel": return {
			title: getEntityName(dictionaries.entities[detail.entityId]),
			subTitle: `As ${detail.title ?? (!!detail.domain ? roleDomains[detail.domain] : !!detail.position ? rolePositions[detail.position] : "")}`,
			avatar: HandshakeOutlineIcon
		};
		case "InvestorRelationshipModel": return {
			title: getEntityName(dictionaries.entities[detail.entityId]),
			subTitle: `As Investor of ${(() => {
				if (!!detail.sicavIds?.length) return getEntityName(dictionaries.entities[detail.sicavIds[0] ?? 0]);
				if (!!detail.portfolioIds?.length) return dictionaries.portfolios[detail.portfolioIds[0] ?? 0]?.name;
				if (!!detail.investorIds?.length) return getEntityName(dictionaries.entities[dictionaries.relationships[detail.investorIds[0] ?? 0]?.entityId ?? 0]);
				return "";
			})()}`,
			avatar: LocalAtmIcon
		};
		case "PersonModel":
			return {
				title: "Myself",
				subTitle: getEntityName(detail),
				avatar: HomeIcon
			};
		case "CompanyModel":
			return {
				title: getEntityName(detail),
				subTitle: "",
				avatar: BusinessIcon
			};
		case "EntityGroupModel":
			return {
				title: getEntityName(detail),
				subTitle: "",
				avatar: GroupIcon
			};
		case "SicavModel":
			return {
				title: getEntityName(detail),
				subTitle: "",
				avatar: AccountBalanceWalletIcon
			};
	}
}
