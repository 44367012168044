import { Button, Card, CardActions, CardContent, CardMedia, Typography, Link, makeStyles } from "@material-ui/core";
import welcomePicture from "images/key_small.jpg"

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: theme.shadows[24],
        width: 650,
        alignSelf: 'center'
    },
    media: {
        height: 0,
        paddingTop: "40%" // 16:10
    },
    actions: {
        display: "flex",
    }
}));
export interface IWelcomeProps {
    onRequestLogin: () => void;
    logoutUrl: string;
    errorMessage?: string;
}
export default function Welcome({ onRequestLogin, logoutUrl, errorMessage }: IWelcomeProps) {
    const classes = useStyles();
    return <Card className={classes.card}>
        <CardMedia className={classes.media} image={welcomePicture} />
        <CardContent>
            <Typography component="h5" variant="h5">
                Welcome.
            </Typography>
            {!errorMessage && <Typography component="p">
                Please authenticate.
            </Typography>}
            {errorMessage && <Typography component="p" color="error">
                {errorMessage}
            </Typography>}
        </CardContent>
        <CardActions className={classes.actions} disableSpacing={true}>
            <Link href={logoutUrl}>
                Logout from your identity provider
            </Link>
            <p style={{ flexGrow: 1 }} />
            <Button onClick={onRequestLogin}>Authenticate</Button>
        </CardActions>
    </Card>
}
