import { Epic } from "redux-observable";
import { map, mergeMap, tap } from "rxjs/operators";
import { ActionFactories, Action } from "./slice";
import { mapToPayload } from "lib/rxJsUtility";
import { IDocumentProcessExecutionTaskPortalModel, IUniverseScopeTypeModel, processExecutionApi, relationshipsApi, entitiesApi } from "proxy/apiProxy";
import { from, merge, combineLatest } from "rxjs";
import saveAs from "file-saver";
import { mapDefaultRelatedId } from "features/mapDefaultRelatedId";
// import { mapDefaultRelatedId } from "./mapDefaultRelatedId";

export const detailLoad: Epic<Action>
    = action$ => merge(
        // action$.pipe(
        //     mapToPayload("contextPage", "detailLoad"),
        //     map(ActionFactories.detailLoaded)),
        action$.pipe(
            mapToPayload("contextPage", "detailLoad"),
            map(relatedId => ActionFactories.detailProcessesLoad({ relatedId }))),
        combineLatest([
            action$.pipe(mapToPayload("app", "applicationLoaded")),
            action$.pipe(mapToPayload("contextPage", "detailLoad"))
        ]).pipe(
            mergeMap(([{ monitoringMacroScripts }, relatedId]) =>
                from(
                    monitoringMacroScripts
                        .filter(ms => ms.singleScope === relatedId?.type)
                ).pipe(
                    map(i => ActionFactories.detailMonitoringLoad({ relatedId, monitoringId: i.id }))))
        ));
export const loadDueDiligence: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("contextPage", "detailProcessesLoad"),
        mapDefaultRelatedId(action$),
        mergeMap(async ({ relatedId: { id, type } }) => {
            switch (type) {
                case IUniverseScopeTypeModel.Entity: return entitiesApi.getProcessesAsync({ entityId: id });
                case IUniverseScopeTypeModel.Counterparty:
                case IUniverseScopeTypeModel.Role:
                case IUniverseScopeTypeModel.Investor:
                    return relationshipsApi.getProcessesAsync({ relationshipId: id });
            }
        }),
        map(ActionFactories.detailProcessesLoaded));

// export const detailLoad: Epic<Action>
//     = action$ => action$.pipe(
//         mapToPayload("contextPage", "detailLoad"),
//         mergeMap(detailId => detailsApi.getAsync({ detailId })),
//         map(ActionFactories.detailLoaded));
export const loadDashboard: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("contextPage", "detailMonitoringLoad"),
        mapDefaultRelatedId(action$),
        mergeMap(async ({ relatedId: { id, type }, monitoringId }) => {
            switch (type) {
                case IUniverseScopeTypeModel.Entity: return entitiesApi.getMonitoringResultForTargetAsync({ monitoringId, entityId: id });
                case IUniverseScopeTypeModel.Counterparty:
                case IUniverseScopeTypeModel.Role:
                case IUniverseScopeTypeModel.Investor:
                    return relationshipsApi.getMonitoringResultForTargetAsync({ monitoringId, relationshipId: id });
            }
        }),
        map(ActionFactories.detailMonitoringLoaded));

export const generateReport: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("contextPage", "detailReportGenerate"),
        mapDefaultRelatedId(action$),
        mergeMap(async ({ relatedId: { id, type }, reportTemplateId }) => (function () {
            switch (type) {
                case IUniverseScopeTypeModel.Entity: return entitiesApi.generateReportAsync({ reportTemplateId, entityId: id });
                case IUniverseScopeTypeModel.Counterparty:
                case IUniverseScopeTypeModel.Role:
                case IUniverseScopeTypeModel.Investor:
                    return relationshipsApi.generateReportAsync({ reportTemplateId, relationshipId: id });
            }
        })().then(response => ({ response, reportTemplateId }))),
        mergeMap(async ({ response, reportTemplateId }) => {
            saveAs(response.blob, response.fileName);
            return reportTemplateId;
        }),
        map(ActionFactories.detailReportGenerated));

export const saveDueDilFile: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("contextPage", "detailProcessFileSave"),
        mergeMap(i => processExecutionApi.saveProcessFileAsync(i).then(t => ({ task: t as IDocumentProcessExecutionTaskPortalModel, processId: i.id }))),
        map(i => ActionFactories.detailProcessFileSaved(i)));

export const deleteDueDilFile: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("contextPage", "detailProcessFileDelete"),
        mergeMap(i => processExecutionApi.deleteProcessFileAsync(i).then(t => ({ task: t as IDocumentProcessExecutionTaskPortalModel, processId: i.id }))),
        map(ActionFactories.detailProcessFileDeleted));

export const loadDueDilFile: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("contextPage", "detailProcessFileLoad"),
        mergeMap(parameters => processExecutionApi.getProcessFileAsync(parameters)
            .then(fileResponse => ({ fileResponse, parameters }))),
        tap(({ fileResponse: { blob, fileName } }) => saveAs(blob, fileName)),
        map(i => ActionFactories.detailProcessFileLoaded(i.parameters)));

export const loadQuestionnaire: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("contextPage", "detailProcessExecutionLoadQuestionnaire"),
        mergeMap(parameters => processExecutionApi.getProcessExistingExecutionQuestionnaireAsync(parameters)
            .then(state => ({ parameters, state }))),
        map(({ parameters: { id: processId, taskCode }, state: taskState }) => ActionFactories.detailProcessExecutionLoadedQuestionnaire({ processId, taskCode, taskState })));

export const saveQuestionnaire: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("contextPage", "detailProcessExecutionSaveQuestionnaire"),
        mergeMap(parameters => processExecutionApi.saveProcessExecutionQuestionnaireResponseAsync(parameters)
            .then(task => ({ task, parameters }))),
        map(({ parameters: { id: processId }, task }) => ActionFactories.detailProcessExecutionSavedQuestionnaire({ processId, task })));

export const saveComment: Epic<Action>
    = action$ => action$.pipe(
    mapToPayload("contextPage", "detailProcessExecutionSaveComment"),
    mergeMap(payload =>
        processExecutionApi.saveProcessTaskPublicCommentAsync({...payload})
            .then((value) => ({processId: payload.id, task: value}))),
    map(({processId, task}) => ActionFactories.detailProcessExecutionSavedComment({processId, task})));
