import { createStyles, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { useReduxSelections } from "lib/reduxStoreAccess";
import { useMemo } from "react";

const useStyles = makeStyles(theme => createStyles({
    titleHeader: {
        paddingTop: 0,
        paddingBottom: 0,
        fontWeight: "bold",
        width: "auto",
        color: theme.palette.primary.contrastText
    },
    menuButton: {
        marginRight: theme.spacing(2),
        height: 40,
        objectFit: "contain"
    }
}));

export default function ApplicationTitle() {
    const classes = useStyles();
    const { themes } = useReduxSelections("app");
    const { current } = useReduxSelections("contextPage");

    const theme = useMemo(() => {
        if (!current) {
            return null;
        }
        const typeThemes = themes[current.type];
        return typeThemes ? typeThemes[current.id] : null;
    }, [current, themes]);


    // const theme = themes[themeId ?? 0] as (IThemingState | undefined);

    return <ListItem className={classes.titleHeader}>
        {!!theme?.image && <img className={classes.menuButton} alt="company logo" src={theme.image} />}
        {theme?.name && <ListItemText primary={theme.name} />}
    </ListItem>
}
