import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "./MonitoringResults";

export interface IExpandCollapseButtonProps {
    expanded: boolean;
    onChanged: (expanded: boolean) => void;
}
export function ExpandCollapseButton({ expanded, onChanged }: IExpandCollapseButtonProps) {
    const classes = useStyles();
    const handleClick = () => onChanged(!expanded);
    return <IconButton
        className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
        })}
        onClick={handleClick}>
        <ExpandMoreIcon />
    </IconButton>;
}
