import * as React from "react";
import autobind from "autobind-decorator";
import { withStyles, Theme, StyleRules, createStyles, WithStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";

const styles = (theme: Theme): StyleRules => createStyles({
    verticalDirection: {
        width: "100%",
        "& > *:not(:first-child)": {
            marginTop: theme.spacing(2)
        }
    },
    horizontalDirection: {
        width: "100%",
        alignItems: "baseline",
        "& > *:not(:first-child)": {
            marginLeft: theme.spacing(2)
        }
    },
    horizontalDirectionNoBaseline: {
        width: "100%",
        "& > *:not(:first-child)": {
            marginLeft: theme.spacing(2)
        }
    }
});

@autobind
class FieldBox extends React.PureComponent<BoxProps & WithStyles & { noBaseline?: boolean }> {
    public render() {
        const { children, classes, noBaseline, ...props } = this.props;
        return <Box className={this.getClassName()} {...props}>{children}</Box>;
    }
    private getClassName(): string | undefined {
        const { classes, flexDirection, display, noBaseline } = this.props;
        if (display === "flex") {
            if (flexDirection === "column") {
                return classes.verticalDirection;
            }
            else if (noBaseline) {
                return classes.horizontalDirectionNoBaseline;
            }
            else {
                return classes.horizontalDirection;
            }
        }
        return;
    }
}

export default withStyles(styles)(FieldBox);
