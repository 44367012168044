import { Avatar, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Box, Divider } from "@material-ui/core";
import autobind from "autobind-decorator";
import { AccountCircle } from "mdi-material-ui";
import * as React from "react";
import { IProfileModel } from "proxy/apiProxy";
import { withStyles, createStyles } from "@material-ui/core/styles";

const StyledTypography = withStyles(theme =>
    createStyles({
        root: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: "ellipsis",
            marginBottom: 0,
            marginLeft: theme.spacing(1)
        }
    })
)(Box);

export interface IProps {
    user: IProfileModel;
    pictureUrl?: string;
    onLogout?: () => void;
    onMyProfile?: () => void;
    onChangeTenant?: () => void;
}

@autobind
class UserDropdown extends React.PureComponent<IProps, { anchorEl: HTMLButtonElement | undefined }>{
    constructor(props: IProps) {
        super(props);
        this.state = { anchorEl: undefined };
    }
    public render() {
        const { user } = this.props;
        const menuOpened = !!this.state.anchorEl;

        return <>
            <Button
                aria-owns={menuOpened ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleOpenMenu}
                color="inherit"
            >
                {this.props.pictureUrl ? <Avatar src={this.props.pictureUrl} /> : <AccountCircle />}
                &nbsp;<StyledTypography>{user.displayName}</StyledTypography>
            </Button>
            <Popper open={menuOpened} anchorEl={this.state.anchorEl} transition={true} disablePortal={true} placement="bottom-end">
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: (placement === 'bottom' || placement === 'bottom-end' || placement === 'bottom-start') ? 'right top' : 'right bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleCloseMenu}>
                                <MenuList>
                                    {this.props.onMyProfile && <MenuItem onClick={this.handleMyProfile}>Profile</MenuItem>}
                                    {this.props.onChangeTenant && <MenuItem onClick={this.handleChangeTenant}>Change Tenant</MenuItem>}
                                    <Divider />
                                    {this.props.onLogout && <MenuItem onClick={this.handleLogout}>Logout</MenuItem>}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    }

    private handleMyProfile() {
        this.setState({ anchorEl: undefined });
        if (this.props.onMyProfile) {
            this.props.onMyProfile();
        }
    }

    private handleChangeTenant() {
        this.setState({ anchorEl: undefined });
        if (this.props.onChangeTenant) {
            this.props.onChangeTenant();
        }
    }

    private handleLogout() {
        this.setState({ anchorEl: undefined });
        if (this.props.onLogout) {
            this.props.onLogout();
        }
    }

    private handleOpenMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ anchorEl: event.currentTarget });
    }

    private handleCloseMenu() {
        this.setState({ anchorEl: undefined });
    }
}

export default UserDropdown;