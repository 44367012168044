import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Welcome from './Welcome';
import 'typeface-roboto'
import ApplicationBar from "./ApplicationBar";
import welcomeBg from "images/bg1_large_light.jpg";
import { createTheme, Grid, makeStyles, ThemeOptions } from '@material-ui/core';
import { useReduxActions, useReduxSelections } from 'lib/reduxStoreAccess';
import { HotKeys, KeyMap } from 'react-hotkeys';
import { useCallback, useEffect, useMemo } from 'react';
import { AuthProvider } from "react-oidc-context";
import { getUserManager, getConfig } from 'lib/userManager';
import { User } from 'oidc-client-ts';
import Loading from './Loading';
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Page from 'features/ContextPage/Page';

const defaultPalette = {
  primary: {
    dark: "#547d9b",
    light: "#b5deff",
    main: "#84accc",
  },
  secondary: {
    dark: "#c88719",
    light: "#ffe97d",
    main: "#ffb74d",
  },
  text: {
    primary: "#000000",
    secondary: "#808080"
  }
};

export const router = createBrowserRouter([{
  path: "/",
  element: <LoadedApplication />,
  children: [{
    index: true,
    element: <Page />,
  }, {
    path: "page/:type/:id",
    element: <Page />
  }],
}]);

// https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=9FA8DA&secondary.color=FFE082&primary.text.color=000000&secondary.text.color=000000
function convertMinHeightIntoMarginTop(input: any): any {
  return replaceNode(input);
  function replaceNode(i: any): any {
    if (!i || typeof i !== "object") {
      return i;
    }
    const output: Record<string, any> = {};
    for (const key in i) {
      if (i.hasOwnProperty(key)) {
        const element = i[key];
        const propKey = key === "minHeight" ? "marginTop" : key;
        output[propKey] = replaceNode(element);
      }
    }
    return output;
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    bottom: 0,
    display: 'flex',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    // flexGrow: 1
  },
  content: {
    flexGrow: 1,
    display: "flex",
    padding: theme.spacing(3),
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
    width: "100%",
    paddingTop: 64,
    ...convertMinHeightIntoMarginTop(theme.mixins.toolbar)
  },
  gridContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  simpleCardPage: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundImage: `url("${welcomeBg}")`
  }
}));

function useTenantTheme(): ThemeOptions {
  const { parameters } = useReduxSelections("app");
  const themeConfig = parameters?.themeConfig;
  return useMemo(() => {
    const parsed = (function () {
      if (!themeConfig) {
        return {};
      }
      try {
        return JSON.parse(themeConfig);
      } catch {
        return {};
      }
    })();
    const themeOptions = Object.assign({ palette: defaultPalette }, parsed as ThemeOptions | undefined);
    return createTheme(themeOptions);
  }, [themeConfig]);
}

const keyMap: KeyMap = {
  APPLICATION_SEARCH: "ctrl+shift+f"
}

export default function App() {
  const { onSigninCallback } = useReduxActions("app");
  const { currentUser, applicationLoading } = useReduxSelections("app");
  const classes = useStyles();
  useEffect(() => {
    const userManager = getUserManager();
    userManager.getUser().then(user => {
      if (user && !user.expired)
        onSigninCallback(user);
    })
  }, [onSigninCallback]);
  const handleSigninCallback = useCallback((user: User | void) => void (user && onSigninCallback(user)), [onSigninCallback]);

  const muiTheme = useTenantTheme();

  return <AuthProvider onSigninCallback={handleSigninCallback} userManager={getUserManager()} >
    <MuiThemeProvider theme={muiTheme}>
      <div className={classes.root}>
        <CssBaseline />
        {(!applicationLoading && currentUser) && <RouterProvider router={router} />}
        {(!applicationLoading && !currentUser) && <WelcomeApplication />}
        {applicationLoading && <LoadingApplication />}
      </div>
    </MuiThemeProvider>
  </AuthProvider>
}

function LoadedApplication() {
  const classes = useStyles();
  const { searchShortcut } = useReduxActions("app");
  const shortcutHandlers = useMemo(() => ({ APPLICATION_SEARCH: (_?: KeyboardEvent) => searchShortcut() }), [searchShortcut]);
  return <HotKeys keyMap={keyMap} handlers={shortcutHandlers} style={{ width: "100%" }}>
    <ApplicationBar />
    <main className={classes.content}>
      <Grid container={true} justifyContent="center" alignItems="stretch" className={classes.gridContainer} spacing={3}>
        <Outlet />
      </Grid>
    </main>
  </HotKeys>
}

interface IWelcomeApplicationProps {
  error?: Error
}function WelcomeApplication({ error }: IWelcomeApplicationProps) {
  const classes = useStyles();
  const logoutUrl = useMemo(() => {
    const returnTo = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    return getConfig().logoutUrl + '?returnTo=' + encodeURI(returnTo);
  }, []);
  const handleRequestLogin = useCallback(() => void getUserManager().signinRedirect(), []);
  return <div className={classes.simpleCardPage}><Welcome onRequestLogin={handleRequestLogin} logoutUrl={logoutUrl} errorMessage={error?.message} /></div>
}

function LoadingApplication() {
  const classes = useStyles();
  return <div className={classes.simpleCardPage}><Loading /></div>
}
