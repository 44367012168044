import { Typography, Box, Grid, Badge, Divider, LinearProgress } from "@material-ui/core";

interface ISectionGridContainerProps {
    children: React.ReactNode;
    title?: React.ReactNode;
    picture?: string;
    icon?: string;
    loading?: boolean;
    badge?: React.ReactNode;
}
export function SectionGridContainer({ children, title, loading, icon, badge }: ISectionGridContainerProps) {
    return <Grid item container spacing={3} style={{ marginBottom: 50 }}>
        {!!title && <Grid item xs={12}>
            <Box display="flex" alignItems="center">
                {!!icon && <img style={{ height: 40, objectFit: "contain", paddingRight: 16 }} alt="company logo"
                                src={icon} />}
                <Typography variant="h5" component="h2">
                    {!!badge && <Badge badgeContent={badge} color="secondary">{title}</Badge>}
                    {!badge && <>{title}</>}
                </Typography>
            </Box>
            {!loading && <Divider />}
            {loading && <LinearProgress variant="query" />}
            {/* <PictureHeader image={picture} loading={loading}>
            </PictureHeader> */}
        </Grid>}
        <Grid item xs={12} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            {children}
        </Grid>
    </Grid>;
}

