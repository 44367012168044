import { IClassificationTypeModel, IClassificationWithChildrenModel } from "proxy/apiProxy";

export type IClassificationModel = Omit<IClassificationWithChildrenModel, "classifications">;

export type IFlatClassificationTypes = Record<number, Omit<IClassificationTypeModel, "classifications"> & { classifications: Record<number, IClassificationModel> }>;

export default function getFlatClassificationTypes(classificationTypes: IClassificationTypeModel[]): IFlatClassificationTypes {
    const flattenClassificationTypes = {} as IFlatClassificationTypes;
    for (const { classifications, ...classificationType } of classificationTypes) {
        flattenClassificationTypes[classificationType.id] = { ...classificationType, classifications: getFlatClassifications(classifications) };
    }
    return flattenClassificationTypes;
}
function getFlatClassifications(classifications: IClassificationWithChildrenModel[]): Record<number, IClassificationModel> {
    const flatClassifications = {} as Record<number, IClassificationModel>;
    for (const classification of classifications) {
        getFlattenClassifications(flatClassifications, classification);
    }
    return flatClassifications;
}
function getFlattenClassifications(flatClassifications: Record<number, IClassificationModel>, { classifications, ...classification }: IClassificationWithChildrenModel) {
    flatClassifications[classification.id] = classification;
    for (const subClassification of classifications) {
        getFlattenClassifications(flatClassifications, subClassification);
    }
}
