import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Link, SvgIconProps } from "@material-ui/core";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        link: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: theme.palette.text.primary,
            "& > *:not(:first-child)": {
                marginLeft: theme.spacing(1)
            }
        },
        outRoot: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: theme.shape.borderRadius,
            borderColor: "#eeeeee",
            borderStyle: "none",
            outline: "none",
            transition: "border .24s ease-in-out",
            overflow: "hidden"
        },
        root: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: theme.spacing(1),
            backgroundColor: "#f2f2f2",
            color: "#bdbdbd"
        }
    })
);

export interface IReportReferenceProps {
    onFileDrop?: (file: File) => void;
    onClick?: () => void;
    name: string;
    subName?: string;
    disabled?: boolean;
    icon: React.ComponentType<SvgIconProps>;
    processing?: boolean;
}

export default function ReportReference({ onClick, name, icon, processing, subName }: IReportReferenceProps) {
    const elt = { icon };
    const classes = useStyles();
    const handleOnClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    };

    return <Box className={classes.outRoot}>
        <Box className={classes.root}>
            {(!!onClick && !processing) ? (
                <Link className={classes.link} href="#" onClick={handleOnClick}>
                    <elt.icon fontSize="large" />
                    <Box display="flex" flexDirection="column">
                        <Typography>{name}</Typography>
                        {!!subName && <Typography variant="overline">{subName}</Typography>}
                    </Box>
                </Link>
            ) : (
                <Box className={classes.link}>
                    <elt.icon fontSize="large" />
                    <Box display="flex" flexDirection="column">
                        <Typography>{name}</Typography>
                        {!!subName && <Typography variant="overline">{subName}</Typography>}
                    </Box>
                </Box>
            )}
        </Box>
        {processing && <LinearProgress />}
    </Box>
}