import { AnyActionOf } from "lib/store";
import * as AllReducers from './AllReducers';
import AllEpics from './AllEpics';

export const allReducers = Object.keys(AllReducers)
    .sort((a, b) => a > b ? 1 : -1)
    .reduce((a, v) => {
        //@ts-ignore
        a[v] = AllReducers[v].reducer;
        return a;
    }, {} as { [K in keyof typeof AllReducers]: (typeof AllReducers)[K]["reducer"] });

export const ActionFactories = Object.keys(AllReducers)
    .reduce((a, v) => {
        //@ts-ignore
        a[v] = AllReducers[v].ActionFactories;
        return a;
    }, {}) as { [K in keyof typeof AllReducers]: (typeof AllReducers)[K]["ActionFactories"] };



export type IState = { [K in keyof typeof AllReducers]: ReturnType<(typeof AllReducers)[K]["reducer"]> };

type GroupedActionFactories = { [K in keyof typeof AllReducers]: AnyActionOf<(typeof AllReducers)[K]["ActionFactories"]> };
export type IAnyAction = GroupedActionFactories[keyof GroupedActionFactories];






const toArray = <T>(obj: any) => Object.keys(obj).map(i => obj[i] as T);

export const allEpics = Object.keys(AllEpics).reduce((a, key) => [...a, ...toArray(AllEpics[key as keyof (typeof AllEpics)])], [] as any[]);

