import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./layout/App";
import * as features from './features';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import { combineReducers } from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { configureStore } from "@reduxjs/toolkit";
import "core-js";
import { getUserManager } from 'lib/userManager';

const initialState = {};

setTimeout(() => {
  const userManager = getUserManager();
  userManager.getUser().then(user => {
    if (!user || user.expired) {
      console.log("no valid user, redirect to signing");
      userManager.signinRedirect();
    }
  });
}, 1000);


const appReducer = combineReducers({
  ...features.allReducers,
});


const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === "switchTenant") {
    const emptyState = appReducer(undefined, { type: "dummy" });
    // const newState = appReducer(emptyState, { type: "applicationLoad", payload: action.payload });
    return emptyState;
  }
  else {
    return appReducer(state, action);
  }
}

const epicMiddleware = createEpicMiddleware();
const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [epicMiddleware]
});
epicMiddleware.run(combineEpics(...features.allEpics))

ReactDOM.render(<React.StrictMode>
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </Provider>
</React.StrictMode>, document.getElementById("root")!);

registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

