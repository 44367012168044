import { combineLatest, map, share } from "rxjs/operators";
import { mapToPayload } from "lib/rxJsUtility";
import { IUniverseScopeTypeModel } from "proxy/apiProxy";
import { OperatorFunction, Observable } from "rxjs";
import { GetForRelated, GetForRelatedRequired } from "./ContextPage/slice";
import { IAnyAction } from "features";

export type DetailType = IUniverseScopeTypeModel.Entity | IUniverseScopeTypeModel.Role | IUniverseScopeTypeModel.Investor | IUniverseScopeTypeModel.Counterparty;

export interface IContextIdPayload {
    type: DetailType;
    id: number;
}

export function mapDefaultRelatedId<TPayload>(action$: Observable<IAnyAction>): OperatorFunction<GetForRelated<TPayload>, GetForRelatedRequired<TPayload>> {
    return function mapOperation(apiCallPayload$: Observable<GetForRelated<TPayload>>): Observable<GetForRelatedRequired<TPayload>> {
        return apiCallPayload$.pipe(
            combineLatest(action$.pipe(mapToPayload("contextPage", "detailLoadedAll"), share())),
            map(([{ relatedId, ...values }, payload]) => {
                relatedId ??= {
                    id: payload.entity.myId,
                    type: IUniverseScopeTypeModel.Entity
                };
                return { relatedId, ...(values as TPayload) };
            })
        );
    };
}
