import { Typography, Box, Grid } from "@material-ui/core";
import { IReportTemplateCategoryModel, IReportTemplateSummaryModel } from "proxy/apiProxy";
import { GroupedElements } from "lib/groupElements";
import Divider from '@material-ui/core/Divider';
import FileChartOutlineIcon from 'mdi-material-ui/FilePdfBox';
import ReportReference from "components/ReportReference";
// import { ReportElement } from "./InnerPortalScreen";

interface IReportsPanelProps {
    reports: GroupedElements<IReportTemplateSummaryModel>;
    onClick(report: IReportTemplateSummaryModel): void;
    reportTemplateCategoryDictionary: Record<number, IReportTemplateCategoryModel>;
    reportIssuing: Record<number, boolean>;
}
export function ReportsPanel({ reports, onClick, reportTemplateCategoryDictionary, reportIssuing }: IReportsPanelProps) {
    return <>{reports.map(({ key: categoryId, elements }, ii) => <Box key={`${categoryId}${ii}`}>
        {!!categoryId && <Typography gutterBottom={true} variant="h4" component="h2">{reportTemplateCategoryDictionary[categoryId].name}</Typography>}
        {(!!categoryId || reports.length > 1) && <Divider style={{ marginBottom: 16 }} />}
        <Grid container spacing={3}>
            {elements.map(elt => <Grid key={elt.id} item xs={12}>
                <ReportPanel
                    onClick={onClick}
                    elt={elt}
                    issuing={reportIssuing[elt.id]} />
            </Grid>)}
        </Grid>
    </Box>)}
    </>;
}
interface IReportPanelProps {
    elt: IReportTemplateSummaryModel;
    onClick: (elt: IReportTemplateSummaryModel) => void;
    issuing?: boolean;
}
function ReportPanel({ issuing, elt, onClick }: IReportPanelProps) {
    // const issuing = elt.type === "D" ? documentIssuing[elt.id] : reportIssuing[elt.id];
    const handleClick = () => onClick(elt);
    return <Grid item lg={4} md={6} sm={12} alignItems="stretch" style={{ display: "flex" }}>
        <ReportReference icon={FileChartOutlineIcon} name={elt.name} onClick={handleClick} processing={issuing} />
    </Grid>;
}
