import produce from "immer";
import {
    IMonitoringResultsetModel,
    RelationshipModel,
    IGetProcessExecutionsPortalModel,
    IProcessExecutionSaveProcessFileParameters,
    IProcessExecutionDeleteProcessFileParameters,
    IProcessExecutionGetProcessFileParameters,
    IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters,
    IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters,
    IGetMyRelationshipsModel,
    EntityModel,
    IProcessExecutionPortalModel,
    EntitySummaryModel,
    RelationshipSummaryModel,
    PortfolioSummaryModel,
    SecuritySummaryModel,
    IQuestionnaireTaskStateModel,
    IDocumentProcessExecutionTaskPortalModel,
    IQuestionnaireProcessExecutionTaskPortalModel,
    IGetMyEntitiesModel,
    IUniverseScopeTypeModel, IProcessExecutionSaveProcessTaskPublicCommentParameters, ProcessExecutionTaskPortalModel,
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "lib/store";
import { toDictionary } from "lib/utility";
import { DetailType, IContextIdPayload } from "features/mapDefaultRelatedId";


export type GetForRelated<TPayload> = {
    relatedId: IContextIdPayload | null | undefined
} & TPayload
export type GetForRelatedRequired<TPayload> = {
    relatedId: IContextIdPayload
} & TPayload

export type IGenerateReportPayload = GetForRelated<{ reportTemplateId: number }>;
export type IGenerateMonitoringPayload = GetForRelated<{ monitoringId: number }>;
export type IGetProcessesPayload = GetForRelated<{}>;
export type PageContextDetail = EntityModel | RelationshipModel;
export const ActionFactories = produceActionFactories({
    detailLoad: (payload: IContextIdPayload | null | undefined) => payload,
    detailLoadAll: () => undefined,
    detailLoadedAll: (payload: { relationship: IGetMyRelationshipsModel, entity: IGetMyEntitiesModel }) => payload,

    detailMonitoringLoad: (payload: IGenerateMonitoringPayload) => payload,
    detailMonitoringLoaded: (payload: IMonitoringResultsetModel) => payload,

    detailReportGenerate: (payload: IGenerateReportPayload) => payload,
    detailReportGenerated: (payload: number) => payload,

    detailProcessesLoad: (payload: IGetProcessesPayload) => payload,
    detailProcessesLoaded: (payload: IGetProcessExecutionsPortalModel) => payload,

    detailProcessFileSave: (payload: IProcessExecutionSaveProcessFileParameters) => payload,
    detailProcessFileSaved: (payload: IProcessFileSavedPayload) => payload,

    detailProcessFileDelete: (payload: IProcessExecutionDeleteProcessFileParameters) => payload,
    detailProcessFileDeleted: (payload: IProcessFileSavedPayload) => payload,

    detailProcessFileLoad: (payload: IProcessExecutionGetProcessFileParameters) => payload,
    detailProcessFileLoaded: (payload: IProcessExecutionGetProcessFileParameters) => payload,

    detailProcessExecutionLoadQuestionnaire: (payload: IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters) => payload,
    detailProcessExecutionLoadedQuestionnaire: (payload: IProcessExecutionLoadedQuestionnairePayload) => payload,

    detailProcessExecutionSaveQuestionnaire: (payload: IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters) => payload,
    detailProcessExecutionSavedQuestionnaire: (payload: IProcessExecutionSavedQuestionnairePayload) => payload,

    detailProcessExecutionSaveComment: (payload: IProcessExecutionSaveProcessTaskPublicCommentParameters) => payload,
    detailProcessExecutionSavedComment: (payload: IProcessExecutionSavedComment) => payload,
});
export type IMonitoring = { loading: true; } | ({ loading: false; } & IMonitoringResultsetModel)
export interface ITaskState {
    deleting?: boolean;
    loading?: boolean;
    saving?: boolean;
    questionnaireDetails?: IQuestionnaireTaskStateModel;
}

export interface IProcessFileSavedPayload {
    processId: number;
    task: IDocumentProcessExecutionTaskPortalModel;
}
export interface IProcessExecutionLoadedQuestionnairePayload {
    taskCode: string;
    processId: number;
    taskState: IQuestionnaireTaskStateModel;
}
export interface IProcessExecutionSavedQuestionnairePayload {
    processId: number;
    task: IQuestionnaireProcessExecutionTaskPortalModel;
}

export interface IProcessExecutionSavedComment {
    processId: number;
    task: ProcessExecutionTaskPortalModel;
}

export interface IState {
    all: Record<DetailType, Record<number, PageContextDetail>>;
    current?: IContextIdPayload | null;
    loading: boolean;
    monitorings: Record<number, IMonitoring>;
    reportIssuing: Record<number, boolean>;
    processExecutionsLoading: boolean;
    processExecutions: IProcessExecutionPortalModel[];
    processesTaskState: Record<number, Record<string, ITaskState>>;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel | EntityModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<number, SecuritySummaryModel>;
    };
}
const initialState: IState = {
    loading: false,
    all: {
        [IUniverseScopeTypeModel.Investor]: {},
        [IUniverseScopeTypeModel.Counterparty]: {},
        [IUniverseScopeTypeModel.Role]: {},
        [IUniverseScopeTypeModel.Entity]: {},
    },
    dictionaries: {
        entities: {},
        relationships: {},
        portfolios: {},
        securities: {}
    },
    reportIssuing: {},
    monitorings: {},
    processesTaskState: {},
    processExecutions: [],
    processExecutionsLoading: false
};
export type Action = AnyActionOf<typeof ActionFactories>;
export const reducer = (
    state: IState = initialState,
    action: Action
) => produce(state, draft => {
    const getTaskState = (processId: number, taskCode: string) => {
        draft.processesTaskState[processId] ??= {};
        draft.processesTaskState[processId][taskCode] ??= {};
        return draft.processesTaskState[processId][taskCode];
    }
    switch (action.type) {
        case "detailProcessesLoad":
            draft.processExecutionsLoading = true;
            break;
        case "detailProcessesLoaded":
            draft.processExecutionsLoading = false;
            draft.processExecutions = action.payload.processExecutions;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            break;
        case "detailProcessExecutionLoadQuestionnaire":
            getTaskState(action.payload.id, action.payload.taskCode).loading = true;
            break;
        case "detailProcessExecutionLoadedQuestionnaire":
            const taskState = getTaskState(action.payload.processId, action.payload.taskCode);
            taskState.loading = false;
            taskState.questionnaireDetails = action.payload.taskState;
            break;
        case "detailProcessExecutionSaveQuestionnaire":
            getTaskState(action.payload.id, action.payload.taskCode).saving = true;
            break;
        case "detailProcessExecutionSavedQuestionnaire":
            {
                getTaskState(action.payload.processId, action.payload.task.code).saving = false;
                const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
                if (processExecution) {
                    const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                    if (taskIndex >= 0) {
                        processExecution.tasks[taskIndex] = action.payload.task;
                    }
                }
            }
            break;
        case "detailProcessFileLoad":
            getTaskState(action.payload.id, action.payload.taskCode).loading = true;
            break;
        case "detailProcessFileLoaded":
            getTaskState(action.payload.id, action.payload.taskCode).loading = false;
            break;
        case "detailProcessFileSave":
            getTaskState(action.payload.id, action.payload.taskCode).saving = true;
            break;
        case "detailProcessFileSaved":
            {
                getTaskState(action.payload.processId, action.payload.task.code).saving = false;
                const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
                if (processExecution) {
                    const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                    if (taskIndex >= 0) {
                        processExecution.tasks[taskIndex] = action.payload.task;
                    }
                }
            }
            break;
        case "detailProcessFileDelete":
            getTaskState(action.payload.id, action.payload.taskCode).deleting = true;
            break;
        case "detailProcessFileDeleted":
            {
                getTaskState(action.payload.processId, action.payload.task.code).deleting = false;
                const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
                if (processExecution) {
                    const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                    if (taskIndex >= 0) {
                        processExecution.tasks[taskIndex] = action.payload.task;
                    }
                }
            }
            break;
        case "detailProcessExecutionSaveComment":
            getTaskState(action.payload.id, action.payload.taskCode).saving = true;
            break;
        case "detailProcessExecutionSavedComment": {
            getTaskState(action.payload.processId, action.payload.task.code).saving = false;
            const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
            if (processExecution) {
                const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                if (taskIndex >= 0) {
                    processExecution.tasks[taskIndex] = action.payload.task;
                }
            }
        }
            break;
        case "detailMonitoringLoad":
            draft.monitorings[action.payload.monitoringId] = { loading: true, ...action.payload };
            break;
        case "detailMonitoringLoaded":
            if (action.payload.monitoringMacroId in draft.monitorings)
                draft.monitorings[action.payload.monitoringMacroId] = { loading: false, ...action.payload };
            break;
        case "detailLoad":
            draft.loading = true;
            draft.current = action.payload;
            draft.monitorings = {};
            draft.processExecutions = [];
            break;
        case "detailReportGenerate":
            draft.reportIssuing[action.payload.reportTemplateId] = true;
            break;
        case "detailReportGenerated":
            draft.reportIssuing[action.payload] = false;
            break;
        case "detailLoadAll":
            draft.loading = true;
            break;
        case "detailLoadedAll":
            draft.all.Entity = toDictionary(action.payload.entity.all, i => i.id);
            draft.all.Counterparty = toDictionary(action.payload.relationship.all.filter(r => r.type === "CounterpartyRelationshipModel"), i => i.id);
            draft.all.Investor = toDictionary(action.payload.relationship.all.filter(r => r.type === "InvestorRelationshipModel"), i => i.id);
            draft.all.Role = toDictionary(action.payload.relationship.all.filter(r => r.type === "RoleRelationshipModel"), i => i.id);
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entity.entities, ...action.payload.relationship.entities };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationship.relationships };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.relationship.portfolios };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.relationship.securities };
            break;
    }
});
