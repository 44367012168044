import { Action } from "redux";
import { filter, map, share } from "rxjs/operators";
import { OperatorFunction, Observable, Subscriber } from "rxjs";
import { ActionFactories } from "features";

type PayloadActionFactory<AT, PL> = (payload: PL) => { type: AT, payload: PL };
type NoPayloadActionFactory<AT> = () => { type: AT, payload?: undefined };

type ActionFactoriesType = typeof ActionFactories;

export function mapToPayload<KAF extends keyof ActionFactoriesType, A extends Action, T extends (ActionFactoriesType[KAF] & { [AT in keyof T]: NoPayloadActionFactory<AT> | PayloadActionFactory<AT, ReturnType<T[AT]>["payload"]> }), K extends keyof ActionFactoriesType[KAF]>(subStore: KAF, actionType: K)
    : OperatorFunction<A, ReturnType<T[typeof actionType]>["payload"]> {
    return function mapOperation(apiCallPayload$: Observable<A>): Observable<ReturnType<T[typeof actionType]>["payload"]> {
        return apiCallPayload$.pipe(
            filter(i => i.type === actionType),
            map(action => (action as ReturnType<T[typeof actionType]>).payload),
            share()
        );
    };
}

export function withLatestActionPayload<S, KAF extends keyof ActionFactoriesType, A extends Action, T extends (ActionFactoriesType[KAF] & { [AT in keyof T]: NoPayloadActionFactory<AT> | PayloadActionFactory<AT, ReturnType<T[AT]>["payload"]> }), K extends keyof ActionFactoriesType[KAF]>(action$: Observable<A>, subStore: KAF, actionType: K)
    : OperatorFunction<S, [S, ReturnType<T[typeof actionType]>["payload"]]> {
    return function mapOperation(apiCallPayload$: Observable<S>): Observable<[S, ReturnType<T[typeof actionType]>["payload"]]> {
        return apiCallPayload$.pipe(
            withLatestFromBuffer(action$.pipe(
                mapToPayload(subStore, actionType),
            )));
    };
}


// function isOkRestResponse(response: RestResponse<any>): response is OkPayloadResponse<any> {
//     return response.ok && !(response as OkFileResponse).blob;
// }

// should be rehandled for better performances
// export function onlyStatusOk<T = undefined>(): OperatorFunction<ApiPayloadResponse<T> | OkFileResponse | OkEmptyResponse, T> {
//     return function mapOperation(action$: Observable<ApiPayloadResponse<T> | OkFileResponse | OkEmptyResponse>): Observable<T> {
//         return action$.pipe(
//             map(response => response.ok ? response.payload : undefined),
//             onlyNotNull()
//         );
//     }
// }
// export function onlyStatusOk<T = undefined>(): OperatorFunction<ApiPayloadResponse<T>, T> {
//     return function mapOperation(action$: Observable<ApiPayloadResponse<T>>): Observable<T> {
//         return action$.pipe(
//             map(response => response.ok ? response.payload : undefined),
//             onlyNotNull()
//         );
//     }
// }
// export function onlyStatusNotFound<T>()
//     : OperatorFunction<ApiPayloadResponse<T>, ApiPayloadResponse<T>> {
//     return function mapOperation(action$: Observable<ApiPayloadResponse<T>>): Observable<ApiPayloadResponse<T>> {
//         return action$.pipe(
//             map(response => response.status === 404 ? response : undefined),
//             onlyNotNull()
//         );
//     }
// }
// export function onlyStatuses<T>(...statuses: number[])
//     : OperatorFunction<ApiPayloadResponse<T>, ApiPayloadResponse<T>> {
//     return function mapOperation(action$: Observable<ApiPayloadResponse<T>>): Observable<ApiPayloadResponse<T>> {
//         return action$.pipe(
//             filter(response => {
//                 return statuses.map(acceptedStatus => {
//                     if (acceptedStatus >= 100) {
//                         return response.status === acceptedStatus;
//                     }
//                     else if (acceptedStatus >= 10) {
//                         return Math.floor(response.status / 10) === acceptedStatus;
//                     }
//                     else if (acceptedStatus >= 0) {
//                         return Math.floor(response.status / 100) === acceptedStatus;
//                     }
//                     return false;
//                 }).filter(i => i).length > 0;
//                 // return statuses.includes(response.status);
//             })
//         );
//     }
// }
// export function onlyStatusesNonOk<T>()
//     : OperatorFunction<ApiPayloadResponse<T>, NonOkRestResponse> {
//     return function mapOperation(action$: Observable<ApiPayloadResponse<T>>): Observable<NonOkRestResponse> {
//         return action$.pipe(
//             extractType(response => !response.ok ? response : undefined)
//         );
//     }
// }

export function extractType<S, T>(extract: (value: S) => T)
    : OperatorFunction<S, NonNullable<T>> {
    return function mapOperation(action$: Observable<S>): Observable<NonNullable<T>> {
        return action$.pipe(
            filter(i => typeof (i) !== "undefined" && i !== null),
            map(i => extract(i)),
            filter(i => typeof (i) !== "undefined" && i !== null),
            map(i => i as NonNullable<T>)
        );
    }
}
// should be rehandled for better performances
export function onlyNotNull<T>()
    : OperatorFunction<T, NonNullable<T>> {
    return function mapOperation(action$: Observable<T>): Observable<NonNullable<T>> {
        return action$.pipe(
            filter(i => typeof (i) !== "undefined" && i !== null),
            map(i => i as NonNullable<T>)
        );
    }
}
export function castNotNull<T>()
    : OperatorFunction<T, NonNullable<T>> {
    return function mapOperation(action$: Observable<T>): Observable<NonNullable<T>> {
        return action$.pipe(
            map(i => i as NonNullable<T>)
        );
    }
}

interface IObservation<I1, I2> {
    observer: Subscriber<[I1, I2]>;
    i1s: I1[];
}




export function withLatestFromBuffer<I1, I2>(i2$: Observable<I2>): OperatorFunction<I1, [I1, I2]> {
    let lastI2: I2 | undefined;
    i2$.subscribe(unBufferObservations);
    const observations: IObservation<I1, I2>[] = [];
    function unBufferObservations(i2: I2) {
        lastI2 = i2;
        for (const { i1s, observer } of observations ?? []) {
            for (let i1 = i1s.shift(); typeof i1 !== "undefined"; i1 = i1s.shift()) {
                observer.next([i1, i2]);
            }
        }
    }
    return (i1$: Observable<I1>) => new Observable<[I1, I2]>(observer => {
        const observation: IObservation<I1, I2> = { i1s: [], observer };
        observations.push(observation);
        i1$.subscribe({
            complete() {
                observer.complete();
                observations.splice(observations.indexOf(observation), 1);
            },
            error(err) {
                observer.error(err);
                // observations.splice(observations.indexOf(observation), 1);
            },
            next(i1) {
                if (typeof lastI2 === "undefined") {
                    observation.i1s.push(i1);
                }
                else {
                    observation.observer.next([i1, lastI2]);
                }
            }
        });
    })
}
export function onlyChanged<T>(equals: (previous: T, current: T) => boolean) {
    return function (source: Observable<T>): Observable<T> {
        return new Observable(subscriber => {
            let previousValue: { item: T } | undefined = undefined;
            source.subscribe({
                next(value) {
                    if (!previousValue) {
                        previousValue = { item: value };
                        setTimeout(() => subscriber.next(value), 1000);
                    }
                    else {
                        if (!equals(previousValue.item, value)) {
                            previousValue = { item: value };
                            subscriber.next(value);
                        }
                        else {
                            previousValue = { item: value };
                        }
                    }
                },
                error(error) {
                    subscriber.error(error);
                },
                complete() {
                    subscriber.complete();
                }
            })
        });
    }
}
