// import DialogPanel from "tools/components/DialogPanel";
import { createStyles, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import DialogPanel from "components/DialogPanel";
import { ICompanySummaryModel } from "proxy/apiProxy";
import { useCallback } from "react";

export interface ITenantsDialogProps {
    onTenantSelected: (tenantId: number) => void;
    onCancel: () => void;
    accessibleTenants: ICompanySummaryModel[];
    tenantsImageUrls: Record<number, string>;
    opened: boolean
}

export default function TenantsDialog({ onCancel, onTenantSelected, opened, accessibleTenants, tenantsImageUrls }: ITenantsDialogProps) {
    return <DialogPanel
        onBackClick={onCancel}
        title="Switch tenant"
        isOpened={opened}>
        <List component="nav" aria-label="main mailbox folders">
            {accessibleTenants.map(tenant => <TenantItem key={tenant.id} onSelected={onTenantSelected} tenant={tenant} imageUrl={tenantsImageUrls[tenant.id]} />)}
        </List>
    </DialogPanel>;
}
const useStyles = makeStyles(theme => createStyles({
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    }
}));

interface ITenantItemProps {
    onSelected: (tenantId: number) => void;
    tenant: ICompanySummaryModel;
    imageUrl?: string;
}
function TenantItem({ onSelected, tenant: { id, name }, imageUrl }: ITenantItemProps) {
    const classes = useStyles();
    const handleSelected = useCallback(() => onSelected(id), [onSelected, id]);
    return <ListItem button={true} onClick={handleSelected}>
        <ListItemIcon>
            {!!imageUrl && <img className={classes.menuButton} style={{ height: 40, objectFit: "contain" }} alt="company logo" src={imageUrl} />}
        </ListItemIcon>
        <ListItemText primary={name} />
    </ListItem>
}


// {!!currentTenantImageUrl && <img className={classes.menuButton} style={{ height: 40, objectFit: "contain" }} alt="company logo" src={currentTenantImageUrl} />}