import { Typography, Box, LinearProgress } from "@material-ui/core";
import { MonitoringResult } from "components/global/MonitoringResults";
import Divider from '@material-ui/core/Divider';
import { IMonitoring } from "./IPortalScreenState";

export interface IMonitoringProps {
    monitoring: IMonitoring;
}
export default function Monitoring({ monitoring }: IMonitoringProps) {
    return <Box>
        <Typography variant="h4" component="h2">{monitoring.name}</Typography>
        {!monitoring.loading && <Divider style={{ marginBottom: 16 }} />}
        {monitoring.loading && <LinearProgress variant="query" />}
        {!monitoring.loading && <MonitoringResult result={monitoring.result} />}
    </Box>;
}
// export default function Monitoring({ monitoringId }: IMonitoringProps) {
//     const { monitorings: { [monitoringId]: monitoring } } = useReduxSelections("entity");
//     return <Box>
//         <Typography variant="h4" component="h2">{monitoring.name}</Typography>
//         {!monitoring.loading && <Divider style={{ marginBottom: 16 }} />}
//         {monitoring.loading && <LinearProgress variant="query" />}
//         {!monitoring.loading && <MonitoringResult result={monitoring.result} />}
//     </Box>;
// }
