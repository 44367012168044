import { useCallback, useMemo, useState } from "react";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Chip,
    ChipTypeMap,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    makeStyles,
    Paper,
    Tooltip,
    Typography
} from "@material-ui/core";
import {
    IClassificationTypeModel,
    IDocumentProcessExecutionTaskPortalModel,
    IProcessExecutionDeleteProcessFileParameters,
    IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters,
    IProcessExecutionGetProcessFileParameters,
    IProcessExecutionPortalModel,
    IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters,
    IProcessExecutionSaveProcessFileParameters, IProcessExecutionSaveProcessTaskPublicCommentParameters,
    IProcessTaskStatusModel,
    IQuestionnaireProcessExecutionTaskPortalModel,
    ProcessExecutionTaskPortalModel
} from "proxy/apiProxy";
import AttachmentIcon from '@material-ui/icons/Attachment';
import CommentIcon from '@material-ui/icons/Comment';
import DropZone from "components/DropZone";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FileDocumentEditOutlineIcon from "mdi-material-ui/FileDocumentEditOutline";
import { SummaryField } from "components/global/SummaryField";
import { ITaskState } from "./IPortalScreenState";
import { fileToBase64, getEnumLabels } from "lib/utility";
import getFlatClassificationTypes from "lib/getFlatClassificationTypes";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SectionGridContainer } from "./SectionGridContainer";
import mailboxesBand from "images/mailboxesBand.jpg";
import { SurveyRunnerDialog, SurveyBeforeCompleteProps, SurveyRunnerResultHandler } from "components/SurveyRunnerDialog";
import { Formik } from "formik";
import FormTextField from "components/fieldComponents/FormTextField";
import SaveIcon from "@material-ui/icons/Save";

export const processTaskStatuses = getEnumLabels(IProcessTaskStatusModel);

const useProcessStyles = makeStyles((theme) => ({
    statusHeading: {
        flexBasis: '20%',
        flexShrink: 0,
    },
}));
interface IProcessExecutionProps {
    processExecution: IProcessExecutionPortalModel;
    tasksState: Record<string, ITaskState> | undefined;
    processClassificationTypes: IClassificationTypeModel[];
    onFileSave: (payload: IProcessExecutionSaveProcessFileParameters) => void;
    onFileDelete: (payload: IProcessExecutionDeleteProcessFileParameters) => void;
    onFileLoad: (payload: IProcessExecutionGetProcessFileParameters) => void;
    onLoadQuestionnaire: (payload: IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters) => void;
    onSaveQuestionnaire: (payload: IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters) => void;
    onCommentSave: (payload: IProcessExecutionSaveProcessTaskPublicCommentParameters) => void;
}

export function ProcessExecution(
    {
        processExecution,
        tasksState = {},
        processClassificationTypes,
        onFileLoad,
        onFileDelete,
        onFileSave,
        onLoadQuestionnaire,
        onSaveQuestionnaire,
        onCommentSave
    }: IProcessExecutionProps) {
    const handleFileLoad = useCallback((p: Omit<IProcessExecutionGetProcessFileParameters, "id">) => onFileLoad({ ...p, id: processExecution.id }), [onFileLoad, processExecution.id]);
    const handleFileDelete = useCallback((p: Omit<IProcessExecutionDeleteProcessFileParameters, "id">) => onFileDelete({ ...p, id: processExecution.id }), [onFileDelete, processExecution.id]);
    const handleFileSave = useCallback((p: Omit<IProcessExecutionSaveProcessFileParameters, "id">) => onFileSave({ ...p, id: processExecution.id }), [onFileSave, processExecution.id]);

    const handleLoadQuestionnaire = useCallback((p: Omit<IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters, "id">) => onLoadQuestionnaire({ ...p, id: processExecution.id }), [onLoadQuestionnaire, processExecution.id]);
    const handleSaveQuestionnaire = useCallback((p: Omit<IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters, "id">) => onSaveQuestionnaire({ ...p, id: processExecution.id }), [onSaveQuestionnaire, processExecution.id]);

    const handleCommentSave = useCallback((p: Omit<IProcessExecutionSaveProcessTaskPublicCommentParameters, "id">) =>
        onCommentSave({ ...p, id: processExecution.id }), [onCommentSave, processExecution.id]);

    const classifications = useMemo(() => {
        const classificationTypes = getFlatClassificationTypes(processClassificationTypes);
        const classificationLabels: { label: string, value: string }[] = [];
        for (const key in processExecution.classifications) {
            if (Object.prototype.hasOwnProperty.call(processExecution.classifications, key)) {
                const cId = processExecution.classifications[key];
                const ct = classificationTypes[key as unknown as number];
                classificationLabels.push({ label: ct.name.en, value: ct.classifications[cId].name.en })
            }
        }
        return classificationLabels;
    }, [processClassificationTypes, processExecution.classifications]);

    const title = useMemo(() => {
        if (!classifications.length) {
            return "Submit Documents";
        }
        return <Box display="flex">
            {classifications.map(cl => <Box key={cl.label}>{cl.value}</Box>)}
        </Box>
    }, [classifications]);

    return <SectionGridContainer picture={mailboxesBand} title={title} badge={processExecution.tasks.length}>
        <Grid container spacing={10}>
            {processExecution.tasks.map(task => <Grid key={task.code} item xs={6}>
                <Paper style={{ padding: 16, display: "flex", flexDirection: "column", gap: 8 }}>
                    <TaskHeader task={task} saveComment={handleCommentSave} />
                    <TaskHeaderDivider taskState={tasksState[task.code]} />
                    {task.type === "DocumentProcessExecutionTaskPortalModel" && <DocumentTaskDetails
                        task={task}
                        onFileDelete={handleFileDelete}
                        onFileLoad={handleFileLoad}
                        onFileSave={handleFileSave} />
                    }
                    {task.type === "QuestionnaireProcessExecutionTaskPortalModel" && <QuestionnaireTaskDetails
                        task={task}
                        taskState={tasksState[task.code]}
                        onLoadQuestionnaire={handleLoadQuestionnaire}
                        onSaveQuestionnaire={handleSaveQuestionnaire} />
                    }
                </Paper>
            </Grid>)}
        </Grid>
    </SectionGridContainer>
}
interface ILinearProcessTaskProps {
    taskState: ITaskState | undefined;
}
function TaskHeaderDivider({ taskState: { saving, loading, deleting } = {} }: ILinearProcessTaskProps) {
    if (saving || loading || deleting) return <LinearProgress variant="query" />;
    return <Divider />
}
interface IDocumentTaskDetailsProps {
    task: IDocumentProcessExecutionTaskPortalModel;
    onFileSave: (payload: Omit<IProcessExecutionSaveProcessFileParameters, "id">) => void;
    onFileDelete: (payload: Omit<IProcessExecutionDeleteProcessFileParameters, "id">) => void;
    onFileLoad: (payload: Omit<IProcessExecutionGetProcessFileParameters, "id">) => void;
}

function DocumentTaskDetails({ task, onFileLoad, onFileDelete, onFileSave }: IDocumentTaskDetailsProps) {
    const handleClick = useCallback(() => onFileLoad({ taskCode: task.code }), [task.code, onFileLoad]);
    const handleDelete = useCallback(() => onFileDelete({ taskCode: task.code }), [task.code, onFileDelete]);
    const handleSave = useCallback(async (file: File) => {
        const openedFile = await fileToBase64(file);
        onFileSave({
            taskCode: task.code,
            fileModel: {
                data: openedFile.content,
                mimeType: openedFile.mimeType,
                name: openedFile.fileName
            },
        });
    }, [onFileSave, task.code]);

    return <>
        <Box>
            <DropZone
                emptyLabel="Drop the document here"
                disabled={![IProcessTaskStatusModel.Open, IProcessTaskStatusModel.InProgress].includes(task.status)}
                fileName={task.fileName}
                onClick={handleClick}
                onFileOpen={handleSave}
                onClear={handleDelete} />
        </Box>
    </>;
}

interface ITitleBadgeContentProps {
    task: ProcessExecutionTaskPortalModel;
}
function TitleBadgeContent({ task }: ITitleBadgeContentProps) {
    let hasAnswers = false;
    switch (task.type) {
        case "DocumentProcessExecutionTaskPortalModel":
            if (!!task.fileName) {
                hasAnswers = true;
            }
            break;
        case "QuestionnaireProcessExecutionTaskPortalModel":
            if (!!task.filledById) {
                hasAnswers = true;
            }
            break;
    }
    return <>
        <Typography variant="h6">{task.name?.en}</Typography>
        {task.isMandatory && <Tooltip title="Required"><ErrorOutlineIcon fontSize="small" /></Tooltip>}
        {hasAnswers && <Tooltip title="Has answers"><CheckCircleIcon fontSize="small" /></Tooltip>}
    </>;
}
function TitleIcon({ task }: ITitleBadgeContentProps) {
    switch (task.type) {
        case "DocumentProcessExecutionTaskPortalModel": return <AttachmentIcon />
        case "QuestionnaireProcessExecutionTaskPortalModel": return <FileDocumentEditOutlineIcon />
    }
}

interface ITaskHeaderProps {
    task: ProcessExecutionTaskPortalModel;
    saveComment: (payload: Omit<IProcessExecutionSaveProcessTaskPublicCommentParameters, "id">) => void;
}

function TaskHeader({ task, saveComment }: ITaskHeaderProps) {
    const classes = useProcessStyles();

    const [commenting, setCommenting] = useState(false);

    const onCommentClick = useCallback(() => {
        setCommenting(!commenting);
    }, [commenting])
    return <>
        <Box display="flex" flexDirection="row" width="100%" alignItems="center" gridGap={16}>
            <TitleIcon task={task}  />
            <Box display="flex" alignItems="center" gridGap={4}>
                <TitleBadgeContent task={task}  />
            </Box>
            <Box flexGrow={1}  />
            <Box>
                <Typography className={classes.statusHeading}>
                    <ProcessStatusBadge size="small" status={task.status}  />
                </Typography>
            </Box>
            <IconButton size="small" onClick={onCommentClick}>
                <Badge title="Comment" color="secondary" variant="dot" invisible={!task.publicComment}>
                    <CommentIcon  />
                </Badge>
            </IconButton>
        </Box>
        {commenting && <Box paddingBottom={4}>
            <Formik initialValues={{ publicComment: task.publicComment }}
                onSubmit={(values, actions) => {
                    saveComment({ taskCode: task.code, publicComment: values.publicComment });
                    actions.setSubmitting(false);
                    setCommenting(false);
                }}>{({ submitForm, isSubmitting, dirty }) => (
                    <>
                        <FormTextField label="Comment" name="publicComment" multiline fullWidth />
                        <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon />}
                            disabled={!dirty || isSubmitting}
                            onClick={submitForm}>
                            Save Comment
                        </Button>
                    </>
                )}
            </Formik>
        </Box>}
        <Box>
            {task.description?.en &&
                <Typography variant="caption">
                    {task.description?.en}
                </Typography>
            }
        </Box>
    </>
}


interface IQuestionnaireTaskDetailsProps {
    task: IQuestionnaireProcessExecutionTaskPortalModel;
    taskState: ITaskState | undefined;
    onLoadQuestionnaire: (payload: Omit<IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters, "id">) => void;
    onSaveQuestionnaire: (payload: Omit<IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters, "id">) => void;
}
function QuestionnaireTaskDetails({ task, taskState: { loading, saving, questionnaireDetails } = {}, onLoadQuestionnaire, onSaveQuestionnaire }: IQuestionnaireTaskDetailsProps) {
    const { responses, template } = questionnaireDetails ?? {}
    const [isSurveyOpened, setIsSurveyOpened] = useState<boolean>(false);

    const handleReset = useCallback(() => {
        setIsSurveyOpened(false);
        onLoadQuestionnaire({ getPreviousAnswers: false, taskCode: task.code });
        setIsSurveyOpened(true);
    }, [onLoadQuestionnaire, task.code]);

    const handleOpenQuestionnaire = useCallback(() => {
        onLoadQuestionnaire({ getPreviousAnswers: true, taskCode: task.code });
        setIsSurveyOpened(true);
    }, [onLoadQuestionnaire, task.code]);

    const handleCloseCancel = useCallback(() => {
        setIsSurveyOpened(false);
    }, []);

    const handleComplete = useCallback<SurveyRunnerResultHandler>((values) => {
        onSaveQuestionnaire({ taskCode: task.code, response: values, complete: true });
        setIsSurveyOpened(false);
    }, [onSaveQuestionnaire, task.code]);

    const handleSave = useCallback<SurveyRunnerResultHandler>((values) => {
        onSaveQuestionnaire({ taskCode: task.code, response: values, complete: false });
    }, [onSaveQuestionnaire, task.code]);

    // Keep in sync with ProcessExecutionService.IsTaskEditableOnPortal
    if (task.status !== IProcessTaskStatusModel.Open && task.status !== IProcessTaskStatusModel.InProgress) {
        return <Box>
            {isSurveyOpened && <SurveyRunnerDialog
                template={template} initialValues={responses}
                onCancel={handleCloseCancel} cancelLabel="Close"
                readOnly={true}
            />}
            <Box display="flex" justifyContent="space-evenly">
                <Button onClick={handleOpenQuestionnaire} disabled={saving || loading}
                    endIcon={(saving || loading) && <CircularProgress size={16} />}>
                    View current answers
                </Button>
            </Box>
        </Box>;
    }
    else {
        return <Box>
            {(!!template && !loading && isSurveyOpened) &&
                <SurveyRunnerDialog
                    template={template} initialValues={responses}
                    onCancel={handleCloseCancel} cancelLabel="Discard"
                    onComplete={handleComplete} completeLabel="Submit"
                    onOk={handleSave} okLabel="Save"
                    onReset={handleReset} resetLabel="Reset"
                    BeforeComplete={ConfirmAnswerCompleteDialog}
                />}
            <Box display="flex" justifyContent="space-evenly">
                <Button onClick={handleOpenQuestionnaire} disabled={saving || loading}
                    endIcon={(saving || loading) && <CircularProgress size={16} />}>
                    {!task.filledById ? "Answer questionnaire" : "View and edit current answers"}
                </Button>
            </Box>
            {(!!task.error) && <Grid item xs={12}>
                <SummaryField label="Error" value={task.error} error />
            </Grid>}
        </Box>;
    }
}
function ConfirmAnswerCompleteDialog({ onConfirm, onCancel }: SurveyBeforeCompleteProps) {
    return <Dialog open={true}>
        <DialogTitle>Confirm final submission?</DialogTitle>
        <DialogContent>
            <DialogContentText>
                You are about to complete and submit your questionnaire.
                Once submitted, you will not be able to edit your responses.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} color="primary">
                Let me review
            </Button>
            <Button onClick={onConfirm} color="primary" autoFocus>
                Complete and Submit
            </Button>
        </DialogActions>
    </Dialog>
}



function getProcessStatusColor(status: IProcessTaskStatusModel) {
    switch (status) {
        case IProcessTaskStatusModel.Refused: return "#cc0000";
        case IProcessTaskStatusModel.Open: return "#D85532";
        case IProcessTaskStatusModel.InProgress: return "#E88A12";
        case IProcessTaskStatusModel.Waiting: return "#8CBDFE";
        case IProcessTaskStatusModel.Done: return "#7CC488";
        case IProcessTaskStatusModel.NotApplicable: return;
        case IProcessTaskStatusModel.NotAvailable: return;
    }
}
function ProcessStatusBadge({ status, size }: { status: IProcessTaskStatusModel; } & Pick<ChipTypeMap["props"], "size">) {
    const backgroundColor = getProcessStatusColor(status);
    const statusLabel = processTaskStatuses[status];
    if (!statusLabel) {
        return <div>Problem!!!</div>;
    }
    return <Chip size={size} variant={(!backgroundColor) ? "outlined" : undefined} style={{ backgroundColor }} avatar={<Avatar>{statusLabel[0]}</Avatar>} label={statusLabel} />;
}
