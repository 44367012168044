// import { bindActionCreators, ActionCreatorsMapObject } from 'redux'
// import { useDispatch } from 'react-redux'
// import { useMemo } from 'react'
// import { ActionFactories, IAnyAction, IState } from "reducers";

export type AnyActionOf<T> = T[keyof T] extends (...args: any) => (infer R) ? R : never;
export type AnyActionTypeOf<T> = keyof T;

export type IReduxHub<AS extends { [AT in keyof AS]: (payload: Parameters<AS[AT]>[0]) => void }> = { [AT in keyof AS]: (payload: Parameters<AS[AT]>[0]) => void };

export type ActionFactoriesOf<AS extends IReduxHub<AS>> = { [AT in keyof AS]: Parameters<AS[AT]>[0] extends undefined ? () => { type: AT } : (payload: Parameters<AS[AT]>[0]) => { type: AT, payload: Parameters<AS[AT]>[0] } };

export function produceActionFactories<AS extends IReduxHub<AS>>(actions: AS): ActionFactoriesOf<AS> {
    return Object
        .keys(actions)
        .filter(key => typeof actions[key as keyof (typeof actions)] === "function")
        .reduce((acc, type) => {
            acc[type] = (payload: any) => ({ type, payload });
            return acc;
        }, {} as Record<string, (p: any) => any>) as ActionFactoriesOf<AS>;
}


// type PayloadActionFactory<AT, PL> = (payload: PL) => { type: AT, payload: PL };
// type NoPayloadActionFactory<AT> = () => { type: AT, payload?: undefined };

// export function useReduxAction<T extends { [AT in keyof T]: NoPayloadActionFactory<AT> | PayloadActionFactory<AT, ReturnType<T[AT]>["payload"]> }, K extends keyof T>(actions: T, actionType: K)
//     : T[K] {
//     const dispatch = useDispatch()
//     return useMemo(
//         () => bindActionCreators(actions[actionType], dispatch) as T[K],
//         [dispatch, actions, actionType]
//     );
// }

// export function useReduxActions<A, M extends ActionCreatorsMapObject<A>>(actions: M): M {
//     const dispatch = useDispatch()
//     return useMemo(
//         () => bindActionCreators(actions, dispatch),
//         [dispatch, actions]
//     );
// }
