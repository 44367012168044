import * as React from 'react';
import { DagreEngine, DiagramEngine, DiagramModel, PathFindingLinkFactory } from '@projectstorm/react-diagrams';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import DiagramEngineContext from './DiagramEngineContext';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Fab, Tooltip } from '@material-ui/core';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
export interface IBodyWidgetProps {
	model: DiagramModel;
	handleLinks?: boolean;
	direction: "TB" | "LR"
}

function BodyWidget({ model, handleLinks, direction }: IBodyWidgetProps) {
	return <DiagramEngineContext.Consumer>
		{engine => <InnerBodyWidget model={model} diagramEngine={engine} handleLinks={handleLinks} direction={direction} />}
	</DiagramEngineContext.Consumer>;
}
interface IInnerBodyWidgetProps {
	model: DiagramModel;
	diagramEngine: DiagramEngine;
	designMode?: boolean;
	handleLinks?: boolean;
	direction: "TB" | "LR"
}

const GraphWrapper = styled.div(({ fullScreen = false }: { fullScreen?: boolean }) => {
	if (!fullScreen) {
		return {
			position: "relative",
			width: "100%",
			height: "100%",
			borderRadius: 5,
			borderStyle: "solid",
			borderWidth: 1,
			borderColor: "lightgray"
		};
	}
	else {
		return {
			position: "fixed",
			top: 0,
			left: 0,
			zIndex: 9999999,
			right: 0,
			bottom: 0
		};
	}
})

const TopRightDiv = styled.div({
	position: "absolute",
	top: 16,
	right: 16,
	zIndex: 9999999
});

const StyledCanvasWidget = styled(CanvasWidget)(({ designMode = false }: { designMode?: boolean }) => {
	if (designMode) {
		return {
			position: "absolute",
			background: "rgb(60, 60, 60)",
			width: "100%",
			height: "100%",
			backgroundSize: "50px 50px",
			backgroundImage: `linear-gradient(
					0deg,
					transparent 24%,
					rgba(255,255,255, 0.05) 25%,
					rgba(255,255,255, 0.05) 26%,
					transparent 27%,
					transparent 74%,
					rgba(255,255,255, 0.05) 75%,
					rgba(255,255,255, 0.05) 76%,
					transparent 77%,
					transparent
				),
				linear-gradient(
					90deg,
					transparent 24%,
					rgba(255,255,255, 0.05) 25%,
					rgba(255,255,255, 0.05) 26%,
					transparent 27%,
					transparent 74%,
					rgba(255,255,255, 0.05) 75%,
					rgba(255,255,255, 0.05) 76%,
					transparent 77%,
					transparent
				)`
		}
	}
	else {
		return {
			position: "absolute",
			// width: "100%",
			// height: "100%",
			top: 0,
			right: 0,
			left: 0,
			bottom: 0,
			background: "#f5f5f5",
			borderRadius: 5,
		}
	}
});

function InnerBodyWidget({ model, diagramEngine, designMode, handleLinks, direction }: IInnerBodyWidgetProps) {
	diagramEngine.setModel(model);
	const [fullScreen, setFullScreen] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			const layoutEngine = new DagreEngine({
				graph: {
					rankdir: direction, //'LR',
					nodesep: 20,
					// ranker: 'longest-path',
					marginx: 20,
					marginy: 20
				},
				includeLinks: handleLinks
			});

			layoutEngine.redistribute(model);
			diagramEngine
				.getLinkFactories()
				.getFactory<PathFindingLinkFactory>(PathFindingLinkFactory.NAME)
				.calculateRoutingMatrix();
			diagramEngine.repaintCanvas();
		}, 0);
	}, [model, diagramEngine, handleLinks, direction]);
	const handleFullScreenSwitch = () => setFullScreen(v => !v);
	return <GraphWrapper fullScreen={fullScreen}>
		<TopRightDiv><Tooltip title="Switch to full screen"><Fab size="small" onClick={handleFullScreenSwitch}><SettingsOverscanIcon fontSize="small" /></Fab></Tooltip></TopRightDiv>
		<StyledCanvasWidget engine={diagramEngine} designMode={designMode} />
	</GraphWrapper>;
}

export default React.memo(BodyWidget);