import * as React from "react";
// import { createStyles, withStyles } from '@material-ui/core/styles';

import {
    ResponsivePie,
    // PieTooltipProps, 
    // ComputedDatum 
} from '@nivo/pie'
// import { Paper } from "@material-ui/core";
import { ValueFormat } from "@nivo/core";

export interface ISimplePieChartArgs<T> {
    rows: T[];
    getValue: (row: T) => number;
    getArgument: (row: T) => string | undefined;
    getSliceLabel?: (value: number) => string;
}
interface IGroupedValues<T> {
    value: number;
    group: string;
    items: T[];
}
// const StyledPaper = withStyles(theme =>
//     createStyles({
//         root: {
//             padding: theme.spacing(1)
//         }
//     })
// )(Paper);

// function getSliceTooltip<T>(getSliceLabel?: (value: number) => string) {
//     return function SliceTooltip<T>({ datum: { data: { group, value }, formattedValue } }: PieTooltipProps<IGroupedValues<T>>) {
//         return <StyledPaper>{group}: {formattedValue}</StyledPaper>;
//         // return <StyledPaper>{group}: {getSliceLabel ? getSliceLabel(value) : String(value)}</StyledPaper>;
//     }
// }
function computeData<T>({ getValue, getArgument, rows }: ISimplePieChartArgs<T>) {
    const values = rows.reduce((acc, v) => {
        const argument = getArgument(v);
        if (!argument) {
            return acc;
        }
        if (!acc[argument]) {
            acc[argument] = {
                value: getValue(v),
                group: argument,
                items: [v]
            };
        }
        else {
            acc[argument].value += getValue(v);
            acc[argument].items.push(v);
        }
        return acc;
    }, {} as Record<string, IGroupedValues<T>>);
    return values;
}
export default function SimplePieChart<T>({ getValue, getArgument, rows, getSliceLabel }: ISimplePieChartArgs<T>) {
    const values = React.useMemo(() => computeData({ getValue, getArgument, rows }), [getValue, getArgument, rows]);

    const valueFormat: ValueFormat<number> = v => getSliceLabel ? getSliceLabel(v) : String(v);
    const chartData = Object.values(values);
    // // @ts-ignore
    // const getRadialLabel = ({ data: { group } }: ComputedDatum<IGroupedValues<T>>) => group;
    const getRadialId = ({ group }: IGroupedValues<T>) => group;
    // // @ts-ignore
    // const handleGetSliceLabel = (v: ComputedDatum<IGroupedValues<T>>) => valueFormat(v.value);
    // const getSliceLabel = (v: ComputedDatum<IGroupedValues<T>>) => formatPrecisePercentage(v.value);
    return <ResponsivePie<IGroupedValues<T>>
        data={chartData}

        margin={{ top: 16, right: 60, bottom: 16, left: 60 }}
        fit={true}
        sortByValue={true}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        // radialLabel={getRadialLabel}
        colors={{ scheme: "nivo" }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}

        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsDiagonalLength={5}
        arcLinkLabelsStraightLength={5}
        arcLinkLabelsTextOffset={6}
        arcLinkLabelsTextColor="#333333"
        // radialLabelsTextColor="#333333"
        // arcLinkLabelsOffset={0}
        // radialLabelsLinkOffset={0}
        // radialLabelsLinkDiagonalLength={5}
        // radialLabelsLinkHorizontalLength={5}
        // radialLabelsLinkStrokeWidth={1}
        // radialLabelsLinkColor={{ from: 'color', modifiers: [] }}

        arcLabelsSkipAngle={10}
        // sliceLabelsSkipAngle={10}
        // sliceLabelsTextColor="#333333"
        // sliceLabel={handleGetSliceLabel}
        valueFormat={valueFormat}
        id={getRadialId}
    // tooltip={getSliceTooltip(getSliceLabel)} 
    />;
}
