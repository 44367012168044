export type GroupedElements<T> = { key?: number, elements: T[] }[]
export function groupElements<T>(elements: T[], getCategoryId: (e: T) => number | undefined): GroupedElements<T> {
    const { notGrouped, groups } = elements.reduce((a, v) => {
        const categoryId = getCategoryId(v);
        if (!categoryId) {
            a.notGrouped.push(v);
        }
        else {
            let group = a.groups[categoryId];
            if (!group) {
                group = [];
                a.groups[categoryId] = group;
            }
            group.push(v);
        }
        return a;
    }, { notGrouped: [], groups: {} } as { notGrouped: T[]; groups: Record<number, T[]>; });
    return [...Object.keys(groups).map(key => ({ key: key as unknown as number, elements: groups[Number(key)] })), { elements: notGrouped }].filter(i => i.elements.length);
}
