import { useReduxActions, useReduxSelections } from "lib/reduxStoreAccess"
import { useCallback, useEffect, useMemo } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useParams } from "react-router";
import { IReportTemplateSummaryModel, IUniverseScopeTypeModel } from "proxy/apiProxy";
import { SectionGridContainer } from "features/AbstractPortal/SectionGridContainer";
import { groupElements } from "lib/groupElements";
import { toDictionary } from "lib/utility";
import { ReportsPanel } from "features/AbstractPortal/ReportsPanel";
import dashboardPicture from "images/dashboardBand.jpg";
import reportPicture from "images/documentsBand.jpg";
import Monitoring from "features/AbstractPortal/Monitoring";
import { ProcessExecution } from "features/AbstractPortal/ProcessExecution";
import { DetailType } from "features/mapDefaultRelatedId";
import { IState, PageContextDetail } from "./slice";
import { getEntityName } from "lib/modelUtils";
import { Link } from "react-router-dom";

export default function Page() {
    const { id: strDetailId, type: strDetailType } = useParams<{ id: string, type: string }>();
    const { detailLoad, detailReportGenerate,
        detailProcessFileSave,
        detailProcessFileDelete,
        detailProcessFileLoad,
        detailProcessExecutionLoadQuestionnaire,
        detailProcessExecutionSaveQuestionnaire,
        detailProcessExecutionSaveComment
    } = useReduxActions("contextPage");

    const state = useReduxSelections("contextPage")
    const { monitorings, reportIssuing, processExecutions, current, processesTaskState } = state;

    const { processClassificationTypes = [], currentUser } = useReduxSelections("app");

    useEffect(() => {
        const detailId = Number(strDetailId);
        if (detailId && !Number.isNaN(detailId))
            detailLoad({ id: detailId, type: strDetailType as DetailType });
        else
            currentUser && detailLoad({ id: currentUser.id, type: IUniverseScopeTypeModel.Entity });
    }, [strDetailId, detailLoad, strDetailType, currentUser]);

    const handleReportGenerate = useCallback(({ id: payload }: IReportTemplateSummaryModel) => {
        detailReportGenerate({ reportTemplateId: payload, relatedId: current });
    }, [detailReportGenerate, current]);

    const { reportTemplates = [], reportTemplateCategories = [] } = useReduxSelections("app");
    const monitoringsLoading = useMemo(() => !!Object.values(monitorings).filter(i => i.loading).length, [monitorings]);
    const reports = useMemo(() => reportTemplates.filter(i => i.singleScope === current?.type), [current?.type, reportTemplates]);
    const reportGroups = useMemo(() => groupElements(reports, v => v.categoryId), [reports]);
    const reportTemplateCategoryDictionary = useMemo(() => toDictionary(reportTemplateCategories, i => i.id), [reportTemplateCategories]);

    return <>
        {(!processExecutions.length && !Object.keys(monitorings).length && !reportGroups.length) && <Typography>No action available</Typography>}
        {!!Object.keys(monitorings).length && <SectionGridContainer picture={dashboardPicture} loading={monitoringsLoading} badge={Object.keys(monitorings).length}>
            <Grid container spacing={3}>
                {Object.values(monitorings)
                    .map(i => !i.loading ? i : null)
                    .filter(i => !!i)
                    .map(monitoring => <Grid key={monitoring!.code} item xs={12}>
                        <Monitoring monitoring={monitoring!} />
                    </Grid>)}
            </Grid>
        </SectionGridContainer>}

        {!!reportGroups.length && <SectionGridContainer picture={reportPicture} title="Documents & Reports" badge={reportGroups.reduce((a, v) => a + v.elements.length, 0)}>
            <ReportsPanel
                onClick={handleReportGenerate}
                reportIssuing={reportIssuing}
                reportTemplateCategoryDictionary={reportTemplateCategoryDictionary}
                reports={reportGroups} />
        </SectionGridContainer>}
        {processExecutions.map(process => <ProcessExecution
            key={process.id}
            processExecution={process}
            tasksState={processesTaskState[process.id]}
            onLoadQuestionnaire={detailProcessExecutionLoadQuestionnaire}
            onSaveQuestionnaire={detailProcessExecutionSaveQuestionnaire}
            onFileDelete={detailProcessFileDelete}
            onFileLoad={detailProcessFileLoad}
            onFileSave={detailProcessFileSave}
            onCommentSave={detailProcessExecutionSaveComment}
            processClassificationTypes={processClassificationTypes} />)}
        <PagesSummary />
    </>;
}
const typePosition: Record<PageContextDetail["type"], number> = {
    "PersonModel": 0,
    "EntityGroupModel": 1,
    "CompanyModel": 2,
    "SicavModel": 3,
    "RoleRelationshipModel": 4,
    "InvestorRelationshipModel": 5,
    "CounterpartyRelationshipModel": 6,
}
const useStyle = makeStyles(theme => ({
    container: {
        display: "grid",
        gap: theme.spacing(1),
        width: "80%",
        gridTemplateColumns: "auto auto auto",
    },
    item: {
        boxShadow: theme.shadows[3],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2)
    }
}))
function PagesSummary() {
    const { all, dictionaries, current } = useReduxSelections("contextPage");
    const { currentUser } = useReduxSelections("app");
    const styles = useStyle();

    let pages = useMemo(() => Object
        .values(all)
        .flatMap(i => Object.values(i))
        .filter(i => i.type !== "PersonModel" || i.id !== currentUser?.id)
        .toSorted((a, b) => typePosition[a.type] - typePosition[b.type]), [all, currentUser?.id]);

    if (current?.id !== currentUser?.id || current?.type !== IUniverseScopeTypeModel.Entity) {
        return null;
    }
    return <div className={styles.container} >
        {pages.map((page, idx) => (<div className={styles.item} key={idx}>
            <PageSummary detail={page} dictionaries={dictionaries} />
        </div>))}
    </div>

}
interface IPageSummaryProps {
    dictionaries: IState["dictionaries"];
    detail: PageContextDetail
}
// const domainLabel = getEnumLabels(IRoleDomainModel);
// const collaborationTypeLabel = getEnumLabels(ICollaborationTypeModel);
// const rolePositionLabel = getEnumLabels(IRolePositionModel);
function PageSummary({ detail, dictionaries }: IPageSummaryProps) {
    const typePath: DetailType = useMemo(() => {
        switch (detail.type) {
            case "CompanyModel":
            case "EntityGroupModel":
            case "PersonModel":
            case "SicavModel": return IUniverseScopeTypeModel.Entity;
            case "CounterpartyRelationshipModel": return IUniverseScopeTypeModel.Counterparty;
            case "InvestorRelationshipModel": return IUniverseScopeTypeModel.Investor;
            case "RoleRelationshipModel": return IUniverseScopeTypeModel.Role;
        }
    }, [detail.type]);
    const href = `/page/${typePath}/${detail.id}`;
    switch (detail.type) {
        case "CounterpartyRelationshipModel":
            return <>
                <Typography variant="h5">
                    <Link to={href} style={{ textDecoration: 'none' }}>{getEntityName(dictionaries.entities[detail.entityId])}</Link>
                </Typography>
                <Typography variant="overline">As Counterparty</Typography>
            </>
        case "InvestorRelationshipModel":
            return <>
                <Typography variant="h5">
                    <Link to={href} style={{ textDecoration: 'none' }}>{getEntityName(dictionaries.entities[detail.entityId])}</Link>
                </Typography>
                <Typography variant="overline">As Investor</Typography>
            </>
        case "RoleRelationshipModel":
            return <>
                <Typography variant="h5">
                    <Link to={href} style={{ textDecoration: 'none' }}>{getEntityName(dictionaries.entities[detail.entityId])}</Link>
                </Typography>
                <Typography display="block" variant="overline">{`As ${detail.title}`}</Typography>
                {/* <Typography display="block" variant="caption">{`Domain: ${domainLabel[detail.domain]}`}</Typography>
                <Typography display="block" variant="caption">{`Position: ${rolePositionLabel[detail.position]}`}</Typography>
                <Typography display="block" variant="caption">{`Collaboration Type: ${collaborationTypeLabel[detail.collaborationType]}`}</Typography> */}
            </>
        case "CompanyModel":
        case "EntityGroupModel":
        case "SicavModel":
            return <>
                <Typography variant="h5">
                    <Link to={href} style={{ textDecoration: 'none' }}>{getEntityName(detail)}</Link>
                </Typography>
                <Typography variant="overline">As Entity</Typography>
            </>
    }
    return null;
}
